.searchHead{
    display: grid;
    grid-template-columns: 1fr 500px;
    align-items: center;
}
.searchLinkDiv{
    display: flex;
}
.searchLink{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 50%;
    padding: 0 30px;
    border: 1px solid var(--cl_lightgrey);
}
.searchLinkActive{
    background-color: var(--cl_lightgrey);
}