.event_review_wrapper{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
.event_review_wrapper h3{
    font-size: 1.2rem;
    display: flex;
    justify-content: space-between;
}
.event_review_showall{
    font-size: var(--font-size-13);
    color: var(--cl_orange);
    font-weight: 500;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-top: 1px solid var(--cl_text_grey);
    padding-top: 10px;
    color: var(--cl_orange);
}
.event_review_showall svg{
    height: 16px;
}

.reviews_container{
    display: flex;
    flex-direction: column;
    gap: 5px;
    /* padding: 5px 0; */
}
.event_review_item{
    display: grid;
    grid-template-columns: 40px 1fr;
    gap: 1rem;
    padding: 5px 0;
}
.event_review_img{
    height: 40px;
    width: 40px;
    border-radius: 50%;

}
.event_review_img img{
    object-fit: cover;
    object-position: center;
    -o-object-fit: cover;
    -o-object-position: center;
    height: 100%;
    width: 100%;
    border-radius: 50%;
}
.event_review_details{
    display: flex;
    flex-direction: column;
    gap: 5px;

}
.event_review_box{
    display: flex;
    gap: 10px;
}
.event_review_details h4{
    font-size: 14px;
}
.no_of_reviews{
    font-size: 11px;
    font-weight: 400;
    color: rgba(125, 125, 141, 1);
}
.event_review_details p{
    font-size: 13px;
    font-weight: 400;
}
.no_reviews{
    font-size: 14px;
    font-weight: 400;
}