.App {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-family: 'Inter', sans-serif;
	position: relative;

	min-height: 100vh;
	/* max-height: 100vh; */
}

.links {
	text-decoration: none;
	color: black;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.wrappercommon {
	padding-top: 110px;
	width: 100%;
}

.centered {
	display: flex;
	align-items: center;
	justify-content: center;
}

/* #zoid-paypal-buttons-uid_d750d7315c_mta6mte6ndm{
	border: 1px solid red !important;
	max-width: 100% !important;
}
.paypal-button{
position: absolute !important ;
left :0 !important;
} */

/* @media (max-width: 500px) { */
#zoid-paypal-buttons-uid_d750d7315c_mta6mte6ndm {
	position: relative;
	display: inline-block;
	width: 100%;
	min-height: 25px;
	/* min-width: 150px; */
	max-width: 100% !important;
	font-size: 0;
}

.paypal-button {
	position: absolute !important;
	left: 0 !important;
}

.PaypalWrapper {
	position: relative;
}


.PaypalWrapper iframe {
	position: absolute !important;
	left: 0px !important;

}

/* } */

/* @media (orientation: landscape) and (min-width: 500px){

}
@media (orientation: portrait) and (min-width: 500px){
  
} */
/* @media (orientation: portrait), (min-width: 500px){
  
}
 */

.container {
	width: 100%;
	min-width: 100%;
	padding: 152px 40px 50px 40px;
}

.minvh {
	min-height: calc(100vh - 560px) !important;
}

.minvh100 {
	min-height: 100vh;
}

.minpx {
	min-height: 250px !important;
}

.min500px {
	min-height: 500px !important;
}

.min400px {
	min-height: 400px !important;
}

.mainBtn {
	border: none;
	/* min-width: 100px; */
	height: 40px;
	padding: 0 15px;
	border-radius: 8px;
	background-color: var(--cl_orange);
	color: white;
	cursor: pointer;
	transition: 0.3s;
	font-weight: 500;
	letter-spacing: 0.5px;
	font-family: 'Inter', sans-serif;
	justify-content: center;
}

.mainBtn:hover {
	background-color: #ff500b;
	font-weight: 500;
	color: white;
}

.mainBtnActive {
	/* background-color: var(--cl_light_orange); */
	background-color: #ff500b;
}

.mainBtnInv {
	background-color: white;
	border: 1px solid rgba(226, 226, 240, 1);
	border-radius: 7px;
	height: 40px;
	padding: 0 15px;
	transition: background-color 0.3s ease-in-out;
	font-family: 'Inter', sans-serif;

}

.btn__inactive {
	pointer-events: none;
	opacity: 0.5;
}

.mainBtnInv:hover {
	/* background-color: var(--cl_bggrey); */
	background-color: var(--cl_light_orange);
}

.mainBtnInv span {
	color: orange !important;
}

.freeze {
	color: rgba(226, 226, 240, 1);
}

.pointer {
	cursor: pointer;
}

@media (min-width: 1400px) {
	.container {
		padding: 152px 14% 50px 14%;
	}
}

.cursor {
	cursor: pointer;
}

.selectnone {
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.centerit {
	display: flex;
	align-items: center;
}

.commonHeading {
	display: flex;
	flex-direction: column;
	gap: 8px;
	padding-left: 2px;
	/*additional*/
}

.commonHeading h2 {
	font-weight: 600;
	font-size: 1.5rem;
}

.commonHeading h3 {
	display: flex;
	align-items: center;
	font-weight: 600;
	font-size: 1.5rem;
}

.commonHeading h3 svg {
	cursor: pointer;
}

.commonHeading h2 svg {
	margin-left: 10px;
	transform: translateY(5px);
	/* position: relative; */
}

.commonHeading span {
	font-size: var(--font-size-13);
	color: rgba(125, 125, 141, 1);
	font-weight: 400;
}

.commonHeading small {
	font-size: var(--font-size-13);
	color: rgba(125, 125, 141, 1);
	font-weight: 400;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.commonHeading small svg {
	transform: rotate(180deg);
}

.noData {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	padding: 30px 0;
	width: 100%;
}

.noDatasvgcontainer {
	background-color: var(--cl_light_orange);
	height: 80px;
	width: 80px;
	max-height: 80px;
	max-width: 80px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.noData svg {
	max-height: 80px;
}

.noData h2,
.errormsg h2 {
	font-size: 1.5rem;
	font-weight: 600;
	text-align: center;
}

.noData span,
.errormsg span {
	font-size: var(--font-size-13);
	font-weight: 400;
	color: rgba(125, 125, 141, 1);
	text-align: center;

}

.error {
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
}

.errormsg {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 24px;
	max-width: 768px;
}

.errormsg svg {
	max-height: 20vh;
	max-width: 768px;
}

.errormsg .mainBtn {
	min-width: 100px;
}

.errorAlt {
	display: flex;
	justify-content: center;
	margin-top: 20px;
}

.errorAlt1 {
	display: flex;
	justify-content: center;
	margin-top: 20px;
	text-align: center;
	min-height: 150px;
}

/* loader */

.skeleton {
	background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80%), rgba(245, 245, 246, 1);
	background-repeat: repeat-y;
	background-size: 50px 500px;
	background-position: 0 0;
	animation: shine 1s infinite;
}

@keyframes shine {
	to {
		background-position: 100% 0;
	}
}

/* dropdown */
.dropdownContainer {
	position: relative;
}

.dropdown {
	position: absolute;
	background-color: white;
	z-index: 2000;
}

/* .active{

}
.inactive{

} */
/* loaders */

.skeletoneventtile {
	min-width: 330px;
	min-height: 360px;
	max-width: 330px;
	max-height: 360px;
}

.featuredprofileloader {
	height: 68px;
}

/* @media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
} */

/* @keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
} */

.recomLayout .eventGrid .recomLayout {
	min-height: 300px;
	display: flex;
	align-items: center;
}

.recomLayout .error {
	min-height: 400px !important;
}

.shareSvg svg {
	transition: transform 0.3s ease-in-out;
	cursor: pointer;
}

.shareSvg svg:hover {
	transform: scale(1.12);
}

.videoTileContainer {
	position: relative;
	display: flex;
	align-items: center;
	margin: 5px 5px 5px 0;

	min-width: 325px;
	max-width: 325px;
	min-height: 325px;
	max-height: 325px;
	border-radius: 10px;
	overflow: hidden;
	background-color: var(--cl_bg-grey);
}

.videoTileContainer img {
	transition: all 0.3s ease-in-out;
}

.videoTileContainer:hover img {
	transform: scale(1.1);
}

.videoTile {
	/* background-size: cover;
  background-position: center center; */
	position: relative;
	/* align-items: flex-start;
  justify-content: flex-end; */

	/* margin: 5px 5px 5px 0; */

	flex-grow: 1;
	/* display: flex;
  flex-direction: column; */
	min-width: 325px;
	max-width: 325px;
	min-height: 325px;
	max-height: 325px;
	border-radius: 10px;
	object-fit: cover;
	-o-object-fit: cover;
	object-position: center;
	-o-object-position: center;
}

.videoTileContainer .videoOverlay {
	position: absolute;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.videoOverlay svg {
	transition: all 0.3s ease-in-out;
}

.videoOverlay:hover svg {
	transform: scale(1.1);
}

.imgOverlay {
	position: absolute;
	width: 100%;
	height: 100%;
	cursor: pointer;

	display: flex;
	flex-direction: column;
	gap: 1px;
	padding-top: 250px;
	padding-left: 30px;
}

.profilevideooverlay {
	flex-direction: column;
}

.profileimgoverlay .mediatileusrviews,
.profilevideooverlay .mediatileusrviews {
	opacity: 0;
	transition: all 0.3s ease-in-out;
}

.profileimgoverlay:hover .mediatileusrviews,
.profilevideooverlay:hover .mediatileusrviews {
	opacity: 1;
}

.mediatileuserdetails {
	display: flex;
	/* background-color: red; */
	width: auto;
}

.mediatileusrdetailswrapper {
	display: flex;
	gap: 5px;
	background-color: rgba(0, 0, 0, 0.6);
	color: white;
	font-size: 0.75rem;
	/* text-transform: uppercase; */
	padding: 1px 8px 1px 1px;
	border-radius: 10px;
}

.mediatileusrdp {
	height: 20px;
	width: 20px;
	border-radius: 50%;
	overflow: hidden;
}

.mediatileusrdp img {
	width: 100%;
	object-fit: cover;
	-o-object-fit: cover;
}

.mediatileusrname {
	padding: 3px 0;
	max-width: 140px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

/* .mediatileusrname span {
  width: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
} */
.mediatileusrname span:hover,
.mediatileusrviews span:hover {
	color: var(--cl_orange);
	cursor: pointer;
}

.mediatileusrviews span {
	background-color: rgba(0, 0, 0, 0.6);
	color: white;
	font-size: 0.65rem;
	border-radius: 10px;
	padding: 3px 8px;
}

.minwid100 {
	min-width: 100px;
}

.minwid60 {
	min-width: 60px;
}

.minhe20 {
	min-height: 20px;
	border-radius: 10px;
}

.minhe10 {
	min-height: 10px;
	border-radius: 5px;
}

.maxwid60 {
	max-width: 60px;
}

.maxwid150 {
	max-width: 150px;
}

.minwid150 {
	min-width: 150px;
}

.minwid200 {
	min-width: 200px;
}

.minwid300 {
	min-width: 300px;
}

.maxwid80p {
	max-width: 80%;
}

.toggleload {
	background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 50%, rgba(255, 255, 255, 0) 80%), rgba(255, 89, 23, 0.6);
	background-repeat: repeat-y;
	background-size: 50px 500px;
	background-position: 0 0;
	animation: shine1 1s infinite;
}

@keyframes shine1 {
	to {
		background-position: 100% 0;
	}
}

.nostory {
	min-height: 100px;
	display: -webkit-flex;
	display: flex;
	-webkit-flex-direction: column;
	flex-direction: column;
	-webkit-align-items: flex-start;
	align-items: flex-start;
	gap: 10px;
}

.nostory h2 {
	font-size: 1rem;
	font-weight: 400;
}

.nostory span {
	font-size: 0.8rem;
	color: #7d7d8d;
	color: var(--cl_textgrey2);
}

.nodatacontainer {
	min-height: 200px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	gap: 10px;
}

.modalStyle h2 {
	text-align: center;
}

.warningSvg svg {
	font-size: 1.8rem;
	color: var(--cl_orange);
}

.backBtn {
	width: 100%;
	display: flex;
	align-items: flex-start;
}

.backBtn svg {
	font-size: 2rem;
	cursor: pointer;
	transition: all 0.3s ease-in-out;
}

.backBtn svg:hover {
	color: var(--cl_orange);
}

.eventspeoplesearch {
	max-width: 511px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	margin-top: 20px;
}

.eventspeoplesearch h4 {
	font-size: 13px;
}

.eventssearchpeopleinput {
	display: grid;
	grid-template-columns: 1fr 20px;
	width: 100%;
	height: 40px;
	padding: 0 10px;
	position: relative;
	border-radius: 8px;
	background-color: var(--cl_bg-grey);
}

.eventssearchpeopleinput input {
	width: 100%;
	height: 40px;
	border: none;
	outline: none;
	background-color: var(--cl_bg-grey);
}

.eventspeoplesearch svg {
	margin: 10px 3px;
	color: black;
	height: 20px;
	width: 20px;
	color: #7d7d8d;
}

.views .featuredProfilesList,
.eventAttending .featuredProfilesList {
	max-width: 511px !important;
}

/* .imageaddcropper .reactEasyCrop_Container{
  width: 100% !important;
} */
/* .imageaddcropper .reactEasyCrop_Container img{
  width: 100% !important;
  height: auto !important;
} */

.css-a1rc6s {
	/* background-color: var(--cl_orange) !important; */
}

.styletimezoneselect {
	margin-left: 58px;
}

.styletimezoneselect>div>div {
	background-color: var(--cl_bg-grey);
	border: none;
	border-radius: 8px;
	font-size: 14px;
	font-family: 'Inter', sans-serif;
}

.timepicker__style__wrapper {
	border: 1px solid darkmagenta !important;
}

.timepicker__style__wrapper .e-popup {
	background-color: red !important;
}

.slick-slider {
	max-height: 500px;
	width: 1000px;
}

.suggestion-conatiner {
	position: relative;
	/* border: 1px solid red; */
}

.suggestion-main-layout {
	position: absolute;
	width: 100%;
	background-color: rgb(255, 255, 255);
	width: 280px;

	background-color: var(--cl_bg-grey);

	z-index: 999999 !important;
	border-radius: 5px;
	max-height: 300px;
	/* border-radius: 8px; */
	overflow-y: auto;

	transition: height 700ms linear;
	right: 0;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	/* top: 0px; */
	/* top: 100px; */
	/* border: 1px solid var(--cl_light_grey); */
}

.suggestion-main-layout::-webkit-scrollbar {
	/* width: 12px; */
}

/* Track */
.suggestion-main-layout::-webkit-scrollbar-track {
	/* background: #e6e3e3d3; */
	background: rgb(224, 224, 226);

	/* border-radius: 0px 8px 8px 0px; */
	overflow: hidden;
}

/* Handle */
.suggestion-main-layout::-webkit-scrollbar-thumb {
	background: rgb(177, 176, 176);
	/* border-radius: 15px; */
	/* border-radius: 15px; */
}

/* Handle on hover */
.suggestion-main-layout::-webkit-scrollbar-thumb:hover {
	background: #555;
	/* border-radius: 15px; */
	/* border-radius: 15px; */
}

.suggestion-list-container {
	padding: 0px 0px;
	/* padding: 0 10px; */
	z-index: 10;
	/* position: absolute; */
}

.suggestion-list-item {
	font-size: 14px;
	list-style: none;
	padding: 10px 10px;
	margin: 0;
	cursor: pointer;
	margin-top: 5px;
	font-weight: 500;
	font-family: 'Inter', sans-serif;

	/* font-weight: 500; */
}

.suggestion-list-item:hover {
	/* background-color: rgba(252, 252, 252, 0.945); */
	background: var(--cl_light_orange);
	border-radius: 5px;
	/* border-radius: 5px; */
}

.fade__image {
	position: absolute;
	bottom: 0;
	width: 100%;
	height: 100%;
	z-index: 2;
	pointer-events: none;
	background: linear-gradient(180deg,
			rgba(255, 255, 255, 0) 0%,
			rgba(207, 207, 207, 0) 55%,
			rgba(102, 93, 93, 0.13489145658263302) 70%,
			rgba(0, 0, 0, 0.9108018207282913) 100%);
}

@-webkit-keyframes fadeChangeEffect {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes fadeChangeEffect {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

@keyframes fadeOff {

	/* 0% {
    opacity: .15;
  } 

  20% {
    opacity: .55;
  }


  60% {
    opacity: 1;
  } 

  80% {
    opacity: .55;

  }


  100%{
    opacity: .15;

  } */
	0% {
		opacity: 0.15;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0.15;
	}
}

@-webkit-keyframes fadeOff {

	/* 0% {
    opacity: .15;
  } 

  20% {
    opacity: .55;
  }


  60% {
    opacity: 1;
  } 

  80% {
    opacity: .55;

  }


  100%{
    opacity: .15;

  } */
	0% {
		opacity: 0.15;
	}

	50% {
		opacity: 1;
	}

	100% {
		opacity: 0.15;
	}
}

.scrollbar-hidden::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE, Edge add Firefox */
.scrollbar-hidden {
	-ms-overflow-style: none;
	scrollbar-width: none;
	/* Firefox */
	scroll-behavior: smooth;
}

.Toastify {
	position: absolute;
	z-index: 999999;
}