.ticket_category_container{
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
.ticket_category_item{
    display: grid;
    grid-template-columns: 40px 1fr;
    gap: 1rem;
    padding: 26px 32px;
    min-height: 92px;
    max-width: 511px;
    width: 100%;
    border: 1px solid rgba(125, 125, 141, 0.16);
    border-radius: 12px;
}
.ticket_category_itemicon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    max-width: 40px;

} 
.ticket_category_itemicon svg{
    height: 40px;
    width: 40px;
}
.ticket_category_itemdetails_container{
    display: grid;
    grid-template-columns: 1fr 20px;
    gap: 2rem;
    align-items: center;
    justify-content: center;
}
.ticket_category_itemdetails span h4{
    font-size: 0.875rem;
}
.ticket_category_itemdetails span small{
    font-size: 0.875rem;
}
.addtickets{
    position: relative;
}
.ticketpricingcategory svg{
    position: absolute;
    top: 5px;
    right: 5px;
}
.ticketnormalinput{
    height: 40px;
    /* width: 511px; */
    border-radius: 8px;
    padding: 0 15px;
    border: none;
    outline: none;
    background-color: var(--cl_lightgrey);
    font-family: "Inter", sans-serif;
}
.ticketmodalfooter{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 15px;
}
.addtickets small{
    margin-left: 0;
}