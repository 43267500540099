.themap{
    display: flex;
    flex-direction: column;
    gap: 40px;
}
/* .css-2b097c-container{
    max-width: 688px;
}
.css-1pahdxg-control:hover{
    border-color: none !important;
}
.css-yk16xz-control{
    border: none;
    border-radius: 8px;
    background-color: rgba(245, 245, 246, 1);
    border-color: none !important;
} */
.info{
    font-size: .8rem;
    font-weight: 400;
    max-width: 200px;
    text-align: center;
    padding: 3px;
}
.circularprogress{
    height: 20px;
    width: 20px;
    color: var(--cl_orange);
}
.circularprogress svg{
    height: 15px;
    width: 15px;
    color: inherit !important;
}
/* .gmnoprint{
    display: none;
} */
.gm-style-cc{
    display: none;
}