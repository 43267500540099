.eventTileBorder {
    margin: 10px 4px;
    cursor: pointer;
  }
  .eventTileBorder:hover > .eventTile {
    /* box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.055); */
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    transform: scale(1.001);
    transition: 0.3s;
  }
  .eventTile {
    /* width: 100%; */
    height: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-width: 330px;
    /* max-height: 440px; */
  
    /* margin-right: 15px 3px; */
  }
  /* .eventTile :hover {
    box-shadow: 0px 0px 10px 10px black;
    transform: scale(1.1);
  } */
  .eventTileImg {
    background-color: var(--cl_light_grey);
    min-width: 330px;
    max-width: 330px;
    min-height: 210px;
    max-height: 210px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
  }
  .eventTileImg {
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
  }
  .eventLocDiv {
    height: 20px;
    width: 60px;
    display: flex;
    align-items: center;
    position: absolute;
    background-color: white;
    border-radius: 30px;
    width: max-content;
    padding: 6px;
    font-size: 12px;
    top: 20px;
    right: 20px;
    max-width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

  }
  .eventLocDiv:hover{
    transform: scale(1.05);
    transition: 0.3s ease-in-out;

  }
  .eventLocDiv:hover span{
    animation: scrolltext 5s linear 2s;
  }
  @keyframes scrolltext {
    0%   {transform: translateX(100%);}
    100% {transform: translateX(-50%);}
  }

  .eventLocDiv svg{
    min-height: 12px;
    min-width: 12px;
    /* background-color: white; */
  }
  .eventDescpDiv {
    background-color: white;
    display: flex;
    flex-direction: column;
    border: 1px solid var(--cl_light_grey);
    /* border-top: none; */
    /* border-radius: 0 0 10px 10px; */
    /* border: 1px solid red; */
    text-align: left;
    padding: 10px 20px 0 20px;
    width: 100%;
    max-width: 340px;
    height: 150px;
  }
  
  
  .eventUsrDiv {
    display: flex;
    flex-direction: row;
    font-size: 12px;
    justify-content: flex-start;
    align-items: center;
    margin-top: 10px;
    /* padding: 0 5px; */
    /* border: 1px solid yellow; */
  }
  
  .eventUsrDp {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    overflow: hidden;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
  }
  .eventUsrDpImg {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  .eventTile .eventUsrDp {
    margin-right: 8px;
  }
  .eventUsrSub {
    margin: 0 3px 0 8px;
    color: rgb(136, 136, 136);
    font-size: .7rem;
    /* height: .7rem;
    height: .7rem; */
    /* min-width: 100px; */
    max-width: 130px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .eventTile .eventUsrSub{
    min-width: 0 !important;
    margin: 0 3px 0 0px;
  }
  .eventUsrSubDate {
    color: rgb(136, 136, 136);
    font-size: .7rem;
    height: .7rem;
    min-width: 100px;
  }
  .eventUsrSub:hover {
    /* text-decoration: underline; */
    color: black;
  }
  /* .eventUsrSub:hover {
    text-decoration: underline;
  } */
  
  /* .eventDescTitle {
    padding: 10px 5px;
    font-weight: 600;
    font-size: 18px;
    max-height: 140px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  
  } */
  .eventDescTitle {
    padding: 10px 0;
    /* margin: 10px 0px; */
    font-weight: 500;
    font-size: 18px;
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
    /* border: 1px solid blue; */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  
  
  .eventfooter {
    display: flex;
  margin-top: auto;
    flex-direction: row;
    justify-content: space-between;
    padding: 0 0 20px 0;
    font-size: 16px;
  }
  .eventfooter div {
    margin-left: auto;
  }
  .profileeventpreview{
    display: flex;
    justify-content: space-between;
  }
  .participantpreview{
    display: flex;
    gap: 0;
  }
  .participantpreview .eventUsrDp:nth-child(1){
    margin: 0;
    border: .5px solid white;
    z-index: 10;
  }
  .participantpreview .eventUsrDp:nth-child(2){
    margin-left: -4px;
    border: .5px solid white;
    z-index: 7;
  }
  .participantpreview .eventUsrDp:nth-child(3){
    margin-left: -13px;
    border: .5px solid white;
    z-index: 5;
  }