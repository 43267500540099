.notificationSettingsContent{
    margin-top: 34px;
}
.settingsCategory{
    padding: 20px 0;
    max-width: 688px;
}
.settingsCategory{
    display: flex;
    flex-direction: column;
    gap: 24px;
}
.notificationSettingsContainer{
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.notificationSettingsItem{
    min-height: 56px;
    border: 1px solid rgba(125, 125, 141, 0.16);
    border-radius: 12px;
    padding: 18px;
    display: grid;
    grid-template-columns: 1fr 50px;
    gap: 40px;
}
.notificationSettingsItem p{
    font-size: 0.875rem;
}

.businessaccountsettingscontent .notificationSettingsItem{
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    display: flex;
    gap: 16px;
    align-items: center;
}
.businessaccountsettingscontent .notificationSettingsItem:hover{
    /* background-color: var(--cl_light_orange); */
    transform: scale(1.03);
}