
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
	color: black;
	background-color: var(--cl_light_orange);
}

.react-calendar__tile--active:enabled:hover{
	background-color: var(--cl_orange) !important;
	color :white !important;



}

.react-calendar__tile--active:first-child{

	color: blue !important;

}
.react-calendar__tile--active:enabled:focus {
	background-color: var(--cl_orange) !important;
	color :white !important;

}
.react-calendar__navigation {
	height: auto !important;
	margin-bottom: 0px !important;
	padding: 10px 0px !important;
}
.react-calendar__tile--hasActive {
	background-color: var(--cl_orange) !important;
	color :white !important;
}


.react-calendar {

	border: 1px solid rgba(125, 125, 141, 0.16);
	border-radius: 12px;
	padding: 10px 15px 25px 15px;
}
.react-calendar__month-view__days {

	display: grid !important;
	grid-gap: 16px 8px;
	grid-template-columns: auto auto auto auto auto auto auto !important;

}
.react-calendar__month-view__weekdays {
	display: grid !important;
	grid-gap: 12px 8px;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr !important;
    text-align: center !important;
    place-items: center;
    margin-bottom:15px !important


}
.react-calendar__month-view__days button {
	margin: 0px !important;

}
.react-calendar__tile {

	display: flex;
	justify-content: center;
	align-items: center;

	border-radius: 50%;
	font-size: 11px;
	font-weight: 600;
	border: 0.5px solid var(--cl_light_grey) !important;
	position: relative;

}
.react-calendar__tile:hover {
	background-color: var(--cl_hover_orange) !important;
    color: var(--cl_orange) !important
    
}
.react-calendar__tile--now {
	background: rgb(167, 63, 3);
	color: white;

    
}

.testccss {
	
	background-color: var(--cl_orange) !important;
	color: white !important;
}

.react-calendar__tile--active {

	background-color: var(--cl_hover_orange) ;
    color: var(--cl_orange);

}

.react-calendar__tile--active:hover {
	background-color: var(--cl_hover_orange) !important;
    color: var(--cl_orange) !important

}


.react-calendar__month-view__days__day--neighboringMonth {
	color: #757575;
	background-color: #f3f3f3;
}

.react-calendar button {
	margin: 6px;
	overflow: visible !important;
}
abbr {
	text-decoration: none;
	font-family: 'Inter' !important;
	font-style: normal;
	font-weight: 700 !important;
	font-size: 12px !important;
}
.react-calendar__month-view__weekdays__weekday {
	color: #757575;
}
.react-calendar__month-view__weekdays abbr {
	text-decoration: none;
	font-size: 10px;
	font-weight: 700;
}
.react-calendar__navigation__label__labelText {

	font-family: 'Inter' !important;
	font-style: normal;
	font-weight: 600 !important;
	font-size: 20px !important;
	line-height: 28px !important;
}

.react-calendar__navigation__prev2-button {
	display: none !important;
}
.react-calendar__navigation__next2-button {
	display: none !important;
}

.react-calendar__navigation__next-button {

	font-size: 24px !important;
	color: var(--cl_textgrey);
    padding-bottom: 3px !important;
    font-weight: 500 !important;
}
.react-calendar__navigation__prev-button {
    
    font-size: 24px !important;
	color: var(--cl_textgrey);
    padding-bottom: 3px !important;
    font-weight: 500 !important;
}

.highlight {
	position: relative;
	z-index: 100 !important;
}
.highlight:after {
	content: '\A';
	width: 5px;
	height: 5px;
	border-radius: 2.5px;
	background-color: var(--cl_orange);
	color: var(--cl_orange);
	position: absolute;
	bottom: -2.5px;
	z-index: 10000 !important;
	transform: translateY(3px);
}
.react-calendar__year-view .react-calendar__tile {
	padding: 2.85em 2.85em !important;
	border: none !important;
}
.react-calendar__decade-view .react-calendar__tile {
	padding: 2em 2em !important;
}
.react-calendar__navigation button {
	min-width: 30px;
	min-height: 45px;
}
.react-calendar__century-view .react-calendar__tile {
	padding: 3em 3em !important;
	border: none !important;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
	background-color: white;
}
