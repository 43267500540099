.addeventcontainer {
	/* min-height: calc(100vh - 450px); */
	min-height: 100vh;
	width: 100%;
	padding: 110px 40px 0 40px;
	display: grid;
	grid-template-columns: 50px 1fr;
}
@media (min-width: 950px) {
	.addeventcontainer {
		grid-template-columns: 295px 1fr;
	}
}
.AddEventSidebar {
	/* display: flex;
    flex-direction: column;
    align-items: flex-start; */
	/* box-shadow: 2px 0px 0px rgba(226, 226, 240, 0.5); */
	/* border: 1px solid blue; */
	/* padding: 0 10px; */
	border-right: 1px solid var(--cl_lightgrey);
	/* padding: 43px 0 0 0px; */
	position: relative;
}
.AddEventSidebarWrapper {
	position: sticky;
	position: -webkit-sticky;
	top: 140px;
}
.AddEventSideTitle {
	display: flex;
	flex-direction: row;
	font-size: 1.5rem;
	font-weight: 600;
}

.AddEventSideIcon {
	width: 32px;
	height: 32px;
	background-color: var(--cl_orange);
	color: white;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.AddEventSubSide {
	display: flex;
	flex-direction: column;
	padding: 20px 0;
}
.AddEventSideBarTitle {
	display: none;
}
.AddEventSub {
	display: flex;
	flex-direction: row;
	padding: 8px 0;
}

.AddEventSubSideIconActive,
.AddEventSubSideIconComplete,
.AddEventSubSideIcon {
	width: 32px;
	height: 32px;
	font-size: 10px;
	font-weight: 600;
	background-color: var(--cl_light_orange);
	color: var(--cl_orange);
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.AddEventSubSideIconActive {
	cursor: default;
}
.AddEventSubSideIcon {
	/* color: rgba(125, 125, 141, 1); */
	color: #7d7d8d;
	background-color: transparent;
	border: 1px solid var(--cl_lightgrey);
	cursor: default;
}
.AddEventSubSideIconComplete {
	color: white;
	background-color: var(--cl_orange);
	cursor: pointer;
}
.AddEventSub:hover .AddEventSubSideIconComplete {
	transform: scale(1.1);
}
/* .AddEventSubSideIconComplete:hover {
    transform: scale(1.1);

  } */
.AddEventSubSideTitle,
.AddEvenSubSideTitleActive,
.AddEventSubSideTitleComplete {
	display: none;
}

.AddEvenSubSideTitleActive {
	color: var(--cl_orange);
}

.AddEventSubSideTitleComplete {
	color: #040405;
}
.AddEventContentLayout {
	display: flex;
	flex-direction: column;
	padding: 25px 0 0 55px;
}
.loclayout {
	padding-bottom: 30px;
}
.AddEventContentTitle {
	height: 80px;
	font-size: 24px;
	font-weight: 600;
	display: flex;
	align-items: flex-start;
	width: 100%;
	border-bottom: 1px solid var(--cl_lightgrey);
	display: flex;
	align-items: center;
	/* padding-bottom: 15px; */

	/* box-shadow: 0px 1px 1px rgba(226, 226, 240, 0.5); */
}
.addeventtype {
	height: 56px;
	width: 320px;
	margin-left: auto;
	display: flex;
	gap: 10px;
}
.addeventtypedivactive,
.addeventtypediv {
	position: relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	align-content: center;
	padding: 0 13px;
	gap: 12px;
	border-radius: 8px;
	min-width: 145px;
	cursor: pointer;
}
.addeventtypedivactive span,
.addeventtypediv span {
	align-items: center;
	font-size: 0.9rem;
	font-weight: 500;
	/* width: 100%;
  text-align: center; */
}
.addeventtypedivactive span svg,
.addeventtypediv span svg {
	width: 16px;
	height: 16px;
	position: absolute;
	top: 5px;
	right: 5px;
}
.addeventtypediv {
	border: 1px solid var(--cl_lightgrey);
}
.addeventtypedivactive {
	border: 1px solid var(--cl_orange);
}
/* .notavailable{
  color: var(--cl_light_grey);
} */
.addeventmaindiv {
	/* margin-top: 45px; */
	/* border: 1px solid red; */
	width: 100%;
	padding-bottom: 55px;
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.AddEventSectTitle {
	font-size: 0.9rem;
	font-weight: 500;
	/* max-width: 511px; */
	display: flex;
	justify-content: space-between;
}
.AddEventSectTitle small {
	margin-left: auto;
	color: red !important;
}

.addeventdatecontainer {
	display: flex;
	flex-wrap: wrap;
	width: auto;
	max-width: 700px;
	/* height: 200px; */
	gap: 5px;
}
.addeventdatecontainer .AddEventSectTitle {
	padding-left: 20px;
	display: grid;
	grid-template-columns: 1fr 1fr;
	/* gap: 10px; */
	justify-content: center;
}
.addeventdatediv {
	height: 100px;
	width: 340px;
	min-width: 340px;
	display: flex;
	flex-direction: column;
	gap: 5px;
	margin-left: 5px;
}
.dateandtime_wrapper .addeventdatediv .datepickerwrapper {
	height: 40px;
	width: 280px;
	margin-left: 58px;
	/* padding: 0 15px; */
}
.addtickets .addeventdatediv .datepickerwrapper {
	height: 40px;
	width: 280px;
	margin-left: 0;
	/* padding: 0 15px; */
}

.addeventdatediv .datepickerwrapper input {
	height: 40px;
	width: 280px;
	/* margin-left: 58px; */
	padding: 0 15px;
	border: none;
	outline: none;
	background-color: var(--cl_bggrey);
	border-radius: 8px;
	font-family: 'Inter', sans-serif;
}

.AddEventDateTime {
	display: grid;
	grid-template-columns: 40px 1fr;
}

.AddEventDateTimeIcon {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	display: flex;
	/* justify-content: center;
  align-items: center;
  background-color: var(--cl_light_orange);
  margin-right: 10px; */
}
.addeventdatetimeinput {
	height: 40px;
	width: 280px;
	margin-left: 58px;
	padding: 0 15px;
	border: none;
	outline: none;
	border-radius: 8px;
	background-color: var(--cl_lightgrey);
	/* color: var(--cl_textgrey); */
	font-size: 0.8rem;
	font-family: 'Inter', sans-serif;
}
/* .AddEventDateTimeInput {
  margin-left: 45px;
} */
.invitesect1 {
	display: flex;
	flex-direction: column;
	gap: 15px;
}
.invitefailed {
	width: 100%;
	padding-left: 25%;
	color: var(--cl_textgrey);
}
.AddEventSectInput {
	height: 40px;
	/* width: 511px; */
	border-radius: 8px;
	padding: 0 15px;
	border: none;
	outline: none;
	background-color: var(--cl_lightgrey);
	font-family: 'Inter', sans-serif;
	/* color: var(--cl_textgrey); */
}
.invitesect1 small {
	color: var(--cl_textgrey);
	margin-left: 5px;
}
.invitesect2 {
	width: 100%;
	height: auto;
	/* min-height: 200px; */
	margin-top: 10px;
	display: flex;
	flex-wrap: wrap;
	gap: 6px;
}
.AddEventUserItem {
	background-color: rgba(125, 125, 141, 0.08);
	border-radius: 12px;
	display: flex;
	flex-direction: row-reverse;
	align-items: center;
	justify-content: space-between;
	min-width: 450px;
	width: 25vw;
	height: 60px;
	min-height: 60px;
	margin-right: 12px;
	padding: 0 20px;
}

.AddEventUserSec1 {
	display: flex;
	flex-direction: row;
	align-items: center;
	/* justify-content: space-between; */
	gap: 10px;
	padding: 0 10px;
	font-weight: 600;
	font-size: 13px;
}
.AddEventUserSec1 .notchecked {
	background-color: white;
}
.AddEventUserSec2 {
	padding: 0 10px;
	color: rgba(125, 125, 141, 1);
	font-weight: 400;
	font-size: 11px;
}

.AddEventUserDp {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	min-width: 32px;
	border-radius: 50%;
	margin-right: 10px;
	object-fit: cover;
	overflow: hidden;
}
.AddEventUserDpImg {
	width: 32px;
	height: 32px;
	object-fit: cover;
	-o-object-fit: cover;
	object-position: center;
	-o-object-position: center;
	border-radius: 50%;
}

.AddEventUserCheckDiv {
	/* width: 24px;
  height: 24px;
  border-radius: 6px;
  background-color: white;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none; */

	display: block;
	position: relative;
	width: 22px;
	height: 22px;
	margin: 12px 20px 12px 0px;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	font-size: 14px;
	color: white;
	/* border: 1px solid black; */
}
.AddEventUserCheckDiv input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}
.Addeventcheckmark {
	position: absolute;
	top: 0;
	left: 0;
	width: 22px;
	height: 22px;
	background-color: rgb(240, 236, 236);
	border: 0.5px rgb(80, 80, 80);
	border-radius: 3px;
}

.AddEventUserCheckDiv:hover input ~ .Addeventcheckmark {
	background-color: #ccc;
}
.AddEventUserCheckDiv input:checked ~ .Addeventcheckmark {
	background-color: #ff5917;
}
.Addeventcheckmark:after {
	content: '';
	position: absolute;
	display: none;
}
.AddEventUserCheckDiv input:checked ~ .Addeventcheckmark:after {
	display: block;
}
.AddEventUserCheckDiv .Addeventcheckmark:after {
	left: 8px;
	top: 3px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 1px 1px 0;
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}

.AddEventFooter {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 142px;
	width: 100%;
	border-top: 1px solid var(--cl_lightgrey);
}

.AddEventNextBtn,
.AddEventCancelBtn {
	/* width: 100px; */
	min-width: 100px;
	height: 40px;
	border-radius: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: var(--cl_orange);
	color: white;
	font-size: 13px;
	font-weight: 500;
	cursor: pointer;
	padding: 0 10px;
	z-index: 9999;
}

.AddEventCancelBtn {
	color: black;
	background-color: white;
	border: 1px solid #e2e2f0;
}

@media (min-width: 1400px) {
	.addeventcontainer {
		padding: 110px 14% 0 14%;
	}
}

/* general */
.adeventimageinputwrapper {
	/* width: 510px; */
	height: 188px;
	border: 1px solid var(--cl_orange);
	border-radius: 14px;
	padding: 4px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.AddEventSectImgInput {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	border-radius: 14px;
	background-color: var(--cl_light_orange);
	/* margin-top: 14px; */
	object-fit: cover;
	-o-object-fit: cover;
	object-position: center;
	object-position: center;
	overflow: hidden;
}

.AddEventSectImgInputimg {
	width: 24px;
	height: 24px;
}
/* .AddEventSectImgInputimgactive .AddEventSectImgInput{
object-fit: cover;
-o-object-fit: cover;
object-position: center;
object-position: center;
} */
.AddEventSectImgInput img {
	transition: all 0.3s ease-in-out;
}
.AddEventSectImgInput .AddEventSectImgInputimgactive {
	/* height: 100%; */
	width: 100%;
}
.AddEventSectImgInput .AddEventSectImgInputimgactive:hover {
	height: 100%;
	width: auto;
	/* width: 100%; */
}
.addeventgenerallayout .addeventmaindiv {
	display: flex;
	flex-direction: column;
	gap: 25px;
}

.addeventratingstatus {
	display: flex;
	align-content: center;
	gap: 16px;
}
.addeventratingstatus .AddEventSectTitle {
	display: flex;
	align-items: center;
}
.addeventratingstatus .MuiSwitch-root {
	transform: translateX(-12px);
}
.addeventratingstatus .MuiSwitch-track {
	background-color: var(--cl_lightgrey);

	height: 20px;
	border-radius: 10px;
	opacity: 1;
}
.addeventratingstatus .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
	background-color: var(--cl_orange);
	opacity: 1 !important;
}
.addeventratingstatus .MuiSwitch-thumb {
	transform: translateY(2.5px);
	background-color: var(--cl_orange);
}
.addeventratingstatus .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb {
	background-color: white;
}
/* .MuiSwitch-track */
/* .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  opacity: 0.5;
} */
.addeventcategoryselection {
	display: flex;
	flex-direction: column;
	gap: 15px;
}
.addeventcategoryselection .exploreSubDiv {
	overflow: hidden;
	flex-wrap: wrap !important;
	width: 100%;
	/* max-width: 540px; */
}
.AddEventSectInputtextarea {
	height: 180px;
	/* width: 511px; */
	border-radius: 8px;
	padding: 10px 15px 0 15px;
	border: none;
	outline: none;
	background-color: var(--cl_lightgrey);
	font-size: 0.9rem;
	line-height: 1.2;
	font-family: 'Inter', sans-serif;
	/* color: var(--cl_textgrey); */
}
.addeventcategoryselection .exploreBoxDiv {
	position: relative;
}
.addeventgenerallayout .exploreBoxDiv svg {
	display: none;
	position: absolute;
	right: 5px;
	top: 5px;
}
#addeventimagename {
	margin-top: 3px;
	font-size: 0.6rem;
	width: auto;
	max-width: 100px;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

@media screen and (min-width: 1400px) {
	.addeventcategoryselection .exploreSubDiv {
		overflow: auto;
		width: 100%;
		/* max-width: 700px; */
	}
	.AddEventSectInputtextarea {
		width: 687px;
	}
}

/* report modal */
.reportcontainer {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	padding: 20px 40px;
}
.reportlist {
	display: flex;
	flex-direction: column;
	gap: 5px;
}
.reportlist li {
	list-style: none;
	height: 40px;
	display: flex;
	align-items: center;
	border-radius: 8px;
	padding-left: 12px;
	font-size: 1rem;
}
.reportlist li:hover {
	background-color: var(--cl_light_orange);
	color: var(--cl_orange);
}
.reportinput div {
	width: 100px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

/* cancel event */
.cancelEvent {
	display: grid;
	grid-template-columns: 1fr 400px;
	width: 100%;
	padding: 150px 40px 50px 40px;
	/* min-height: calc(100vh - 450px); */
	gap: 20px;
}
.canceleventcontent {
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.canceleventmessage {
	display: flex;
	flex-direction: column;
	gap: 25px;
	margin-top: 30px;
}
.canceleventmessage p {
	font-size: 0.9rem;
}
.canceleventmessage textarea {
	border: none;
	outline: none;
	background-color: #f5f5f6;
	border-radius: 8px;
	height: auto;
	min-height: 220px;
	max-width: 700px;
	padding: 15px;
	font-family: 'Inter', sans-serif;
	font-size: 0.9rem;
	resize: none;
}
.canceleventmessage .AddEventFooter {
	max-width: 700px;
	height: auto !important;
	border: none;
}
.canceleventmessage .AddEventFooter .AddEventNextBtn {
	background-color: #ff1717;
}
.canceleventcontainer .eventTile {
	margin-left: auto;
}
@media screen and (min-width: 1400px) {
	.cancelEvent {
		padding: 150px 14% 50px 14%;
	}
}

.addeventtoggle {
	height: 24px;
	width: 45.333px;
	padding: 0 1.335px;
	display: flex;
	align-items: center;
	border-radius: 22px;
	cursor: pointer;
	transition: justify-content 0.3s ease-in-out, background-color 0.3s ease-in-out;
}
.addeventdot {
	background-color: white;
	height: 21.33px;
	width: 21.33px;
	border-radius: 50%;
	transition: transform 0.3s ease-in-out;
}
.addeventtoggleactive {
	background-color: var(--cl_orange);
	/* justify-content: flex-end; */
}
.addeventtoggleinactive {
	background-color: var(--cl_bggrey);
}
.addeventtoggleactive .addeventdot {
	transform: translateX(21.33px);
}
.addeventtoggleinactive .addeventdot {
	transform: translateX(0);
}
.AddEventUserItem .selectbox {
	height: 32px;
	width: 118px;
	border: 1px solid rgba(226, 226, 240, 1);
	border-radius: 6px;
	outline: none;
	background-color: white !important;
	color: black !important;

	font-weight: 500;
	/* padding: 0 10px; */
	text-transform: uppercase;
	z-index: 0 !important;
}

.AddEventRoleAssign option {
	display: inline;
	padding: 0 10px;
	text-align: center;
	text-transform: uppercase;
}
.AddEventUserItem .selectdropdown {
	z-index: 1000 !important;
}
.AddEventUserItem .selectboxinput p {
	font-size: 12px !important;
}
.AddEventUserItem .selectdropdown li {
	font-size: 12px !important;
	z-index: 1000 !important;
}
.AddEventUserItem .selectbox svg {
	fill: black !important;
}
.AddEventUserItem .selectbox svg g path {
	fill: black !important;
	stroke: black !important;
}
.AddEventUserItem .selectbox .selectarrow {
	top: 12px;
}
.Z {
	z-index: 10000;
}
.selectbox1 {
	cursor: pointer;
	position: relative;
	height: 32px;
	width: 118px;
	border: 1px solid rgba(226, 226, 240, 1);
	border-radius: 6px;
	outline: none;
	background-color: white !important;
}
.selectboxinput1 {
	color: black;
	padding-left: 10px;
	display: flex;
	align-items: center;
}
.selectboxinput1 p {
	font-size: 12px;
	font-weight: 500;
	font-family: 'inter', sans-serif;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
	text-transform: uppercase;
}
.selectarrow1 {
	position: absolute;
	right: 10px;
	top: 12px;
	transition: transform 0.3s ease-in-out;
	transform: rotate(0);
}
.selectarrow1 g path {
	stroke: black !important;
	fill: black !important;
}
.arrowdown {
	transform: rotate(-180deg);
}
.arrowleft {
	transform: rotate(-90deg);
}

.selectdropdown1 {
	background-color: white;
	color: black;
	transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
	position: absolute;
	box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
	border-radius: 12px;
	width: 226px;
	right: 0;
}
.selectinactive1 {
	transform: translateY(0);
	visibility: hidden;
	opacity: 0;
}

.selectactive1 {
	transform: translateY(14px);
	visibility: visible;
	opacity: 1;
}
.selectdropdown1 span {
	height: 8px;
	width: 8px;
	background-color: white;
	position: absolute;
	transform: rotate(45deg);
	top: -4px;
	right: 15px;
	/* z-index: 750; */
}
.selectdropdown .ul1 {
	z-index: 1000;
	/* height: 300px;
  max-height: 300px; */
	font-size: 0.9rem;
	padding: 5px 1px 5px 5px;
}
.selectdropdown {
	scrollbar-width: 0px;
}
.selectdropdown .ul1 li {
	height: 40px;
	font-size: 12px;
	font-weight: 500;
	text-transform: uppercase;
	list-style: none;
	/* margin: 3px; */
	/* padding: 6px 10px; */
	display: flex;
	align-items: center;
	padding-left: 10px;
	border-radius: 5px;
	transition: all 0.2s ease-in-out;
	width: 215px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
.selectdropdown .ul1 li:hover {
	color: var(--cl_orange);
	background-color: var(--cl_light_orange);
}
.eventcreated {
	display: grid;
	grid-template-columns: 330px 1fr;
}

.eventTileDiv {
	display: flex;
	flex-direction: column;
	gap: 14px;
}
@media (max-width: 1100px) {
	.eventcreated {
		display: flex;
		flex-direction: column-reverse;
		align-items: center;
		gap: 20px;
	}
	.eventTileDiv {
		align-items: center;
		gap: 20px;
	}
}
.eventcreatedContent .shareinput {
	background-color: var(--cl_bggrey);
}
@media (min-width: 950px) {
	.AddEventSubSideTitle,
	.AddEvenSubSideTitleActive,
	.AddEventSubSideTitleComplete {
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 13px;
		font-weight: 500;
		padding-left: 12px;
		color: rgba(125, 125, 141, 1);
	}
	.AddEventSideBarTitle {
		display: flex;
		align-items: center;
		padding-left: 15px;
	}
}

.dateandtime_wrapper .addeventdatediv .MuiFormControl-root {
	margin-left: 58px;
}
.addtickets .addeventdatediv .MuiFormControl-root {
	margin-left: 0;
}
.addeventdatediv .MuiOutlinedInput-root {
	height: 40px;
	width: 280px;
	background-color: var(--cl_bggrey);
	border: none;
	outline: none;
	border-radius: 8px;
}
.addeventdatediv .MuiOutlinedInput-root input {
	font-size: 0.9rem;
	font-family: 'Inter', sans-serif;
}
.addeventdatediv .MuiSvgIcon-root {
	font-size: 0.9rem;
}
.addeventdatediv .MuiOutlinedInput-notchedOutline {
	display: none;
}
.addeventdatediv .MuiInputLabel-root {
	display: none;
}
/* .css-7lip4c , .css-12ha4i7, .css-118whkv ,.css-1s220mh-MuiButtonBase-root-MuiIconButton-root,.css-12ha4i7, .css-2ujp1m, ..css-92nr1f-MuiButtonBase-root-MuiIconButton-root, .css-92nr1f-MuiButtonBase-root-MuiIconButton-root{
    background-color: var(--cl_orange) !important;
  }
  .css-118whkv, .css-2ujp1m{
    border: 16px solid var(--cl_orange) !important;
  }
  .addeventdatediv .css-1s220mh-MuiButtonBase-root-MuiIconButton-root{
    background-color: var(--cl_orange) !important;
  } */



/*<==><==><==><==><==><==><==>  Updates DateTime Css <==><==><==><==><==><==><==><==>*/



.datetime__section__layout {
	/* border : 1px solid blue; */
}



.datetime__section__wrapper {
	border-bottom: 1px solid var(--cl_lightgrey);
	/* border: 1px solid red; */
	padding: 30px 0px;
	display: flex;
	gap: 15px;

	flex-direction: column;
	width: 100%;
	min-width: 536px;
	max-width: 536px;

	/* width: 600px; */
}

.datetime__section__wrapper .title__wrapper {
	display: flex;
	justify-content: space-between;
}

.datetime__section__wrapper p {
	font-weight: 700;
	font-size: 0.89rem;
}
.datetime__section__wrapper small {
	font-weight: 600;
	font-size: 0.80rem;
}

.datetime__types {
	display: flex;
	gap: 15px;
}

.datetime__calendar__layout {
	padding: 30px 0px;
}
.datetime__selectdatetime__wrapper {
	display: flex;
	justify-content: space-between;
	gap: 20px;
	width: 100%;
}
.datetime__selectdatetime__wrapper .start__datetime {
	position: relative;
	width: 50%;
}
.datetime__selectdatetime__wrapper .end__datetime {
	position: relative;
	width: 50%;
}
.datetime__renderer__wrapper {
	display: flex;
  justify-content: space-between;
	flex-wrap: nowrap;
	/* gap: 10px; */
  width: 100%;
}
.input__timepicker{
  margin-left: 8px;
}
.input__timepicker {
	
	transition: opacity .3s ease-in-out;
}

.input__timepicker input {
	height: 40px !important;
	width: 90px !important;
	/* margin-left: 58px; */
	padding: 0 15px;
	border: none;
	outline: none;
	background-color: var(--cl_bggrey);
	border-radius: 8px;
	font-family: 'Inter', sans-serif;
	outline: none;
}
.input__timepicker label {
	display: none;
}
.input__timepicker fieldset {
	display: none;
}
.input__timepicker .MuiOutlinedInput-root {
	outline: none !important;
	border: none !important;
}
.input__timepicker .MuiOutlinedInput-root:focus {
	outline: none;
	border: none !important;
}
.input__timepicker .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
	font-family: 'Inter', sans-serif;
	font-size: 14px;
}

.reoccur__wrapper{
  /* border: 1px solid red; */
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 600px;
  overflow: unset;
  transition: overflow 1s linear;
}

.reoccur__wrapper__hide{
max-height: 0px;
display: flex;
flex-direction: column;
gap: 20px;
transition: max-height .5s linear;
overflow: hidden;

}

.r__selected{
	border : 1px solid var(--cl_orange) !important;
	transition: border .5s ease-in-out;

}
.frequency__wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0px;
  /* border: 1px solid red; */
}
.frequency__types__wrapper{
/* border: 1px solid blue; */
display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 10px;
}
.frequency__type{
  border: 1px solid var(--cl_border_grey);
  border-radius: 5px;
  min-width: 50px;
  text-align: center;
  padding: 8px 12px;
  font-weight: 600;
	font-size: 0.80rem;
	cursor: pointer;
  
}
.frequency__types__wrapper__month{
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
.frequency__type__month{
	border: 1px solid var(--cl_border_grey);
	/* border-radius: 5px; */
	min-width: 50px;
	text-align: center;
	padding: 8px 12px;
	font-weight: 600;
	  font-size: 0.80rem;
	  cursor: pointer;
	  background-color: white;
	  transition: background-color .5s ease-in-out;
	  
}
.frequency__types__wrapper__year{
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
}
.frequency__type__year{
	border: 1px solid var(--cl_border_grey);
	/* border-radius: 5px; */
	min-width: 75px;
	text-align: center;
	padding: 12px 12px;
	font-weight: 600;
	  font-size: 0.80rem;
	  cursor: pointer;
	  background-color: white;
	transition: background-color .5s ease-in-out;

}

.m__selected{
	background-color : var(--cl_orange) !important;
	border: 1px solid var(--cl_border_grey) !important;
	color: white !important;
	transition: background-color .5s ease-in-out;

}

.everyonce__wrapper{
	/* border: 1px solid red */
}
.everyonce__dropdown__wrapper{

}

.everyonce__wrapper select {
	-webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0px 10px;
	height: 40px !important;
	width: 60px !important;
	/* margin-left: 58px; */
	/* padding: 15px; */
	border: none;
	outline: none;
	background-color: var(--cl_bggrey);
	border-radius: 5px;
	font-family: 'Inter', sans-serif;
	outline: none;
}
.everyonce__wrapper {
	position: relative;
	/* border: 1px solid red; */
  }
  
  /* .everyonce__wrapper::after {
	content:"🢓";
	font-size: 2rem;
	color:grey;
	top: 8px;
	right: 10px;
	position: absolute;
	font-weight: 600;
	pointer-events: none;
  } */
  .timezone__wrapper{
	display: flex;
	justify-content: space-between;
	align-items: center;
  }

  .timezone__select__wrapper input {
	height: 40px !important;
	width: 280px !important;
	/* margin-left: 58px; */
	padding: 0 15px;
	border: none;
	outline: none;
	background-color: var(--cl_bggrey);
	border-radius: 8px;
	font-family: 'Inter', sans-serif;
	outline: none;
}
.multi__select__wrapper{
	/* border: 1px solid red; */
}

.multi__select__wrapper input {
	height: 40px ;
	width: 100%;
	/* width: 280px !important; */
	/* margin-left: 58px; */
	padding: 0 15px;
	border: none;
	outline: none;
	background-color: var(--cl_bggrey);
	border-radius: 8px;
	font-family: 'Inter', sans-serif;
	outline: none;
}


.alert__wrapper{
	display: flex;
	justify-content: space-between;
	align-items: center;
  }


 


/*<==><==><==><==><==><==><==><==><==><==><==><==><==><==><==><==><==><==><==><==><==>*/

/* .css-7lip4c , 
  .css-12ha4i7, 
  .css-118whkv ,
  .css-1s220mh-MuiButtonBase-root-MuiIconButton-root,
  .css-12ha4i7, .css-2ujp1m, 
  .css-92nr1f-MuiButtonBase-root-MuiIconButton-root, 
  .css-92nr1f-MuiButtonBase-root-MuiIconButton-root
  .css-118whkv
  .css-a1rc6s
  .css-a1rc6s{
    background-color: var(--cl_orange) !important;
  }
  .css-118whkv, 
  .css-2ujp1m{
    border: 16px solid var(--cl_light_orange) !important;
  }
  .css-a1rc6s{
    background-color: var(--cl_orange) !important;
  } */
