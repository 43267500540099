.nw_profile{
    display: grid;
    
    /* align-items: center; */
    /* grid-gap: 48px;
    gap: 48px; */
}
.nw_profile .menuContainer{
    padding-top: 40px;
    /* padding-bottom: 1px; */
    position: sticky;
    position: -webkit-sticky;
    top: 106px;
    background-color: white;
    z-index: 100;
    /* box-shadow: rgb(0 0 0 / 20%) 0px 60px 40px -7px; */
    box-shadow: rgb(0 0 0 / 4%) 0px 25px 15px -20px;
}
.nw_profile .eventGrid{
    margin-top: 30px !important;
}
.nw_commoncontainer{
    width: 100%;
    min-width: 100%;
    /* padding: 152px 40px 50px 40px; */
    padding: 152px 20px 50px 20px;
}
.nw_coverphotocontainer{
    max-height: 380px;
    min-height: 300px;
    height: auto;
    width: 100%;
    border-radius: 12px;
    position: relative;
    background-color: var(--cl_bggrey);
}
.nw_coverphotocontainer .nw_coverphotocontainerimg{
    max-height: 380px;
    min-height: 300px;
    height: 100%;
    width: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
    border-radius: 12px;
}
.nw_coverphotocontainer svg{
    position: absolute;
    bottom: -10px;
    right: -10px;
}
.nw_userDetails{
    /* height: 300px; */
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 33px;
    gap: 33px;
    transform: translateY(-60px);
    
}
.nw_user{
    display: flex;
    flex-direction: column;
    /* grid-gap: 30px; */
    /* gap: 30px; */
    align-items: center;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    transition: color 0.3s ease-in-out;
}
.nw_profiledp{
    background-color: white;
    height: 80px;
    width: 80px;
    position: relative;
    border: 1px solid white;
    border-radius: 16px;
    /* box-shadow: rgb(0 0 0 / 5%) 0px 6px 24px 0px, rgb(0 0 0 / 8%) 0px 0px 0px 1px; */
    box-shadow: 0 0 10px hsl(0deg 0% 73% / 50%);
    /* box-shadow: rgb(149 157 165 / 30%) 0px 8px 24px; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.nw_profiledp img{
    height: 72px;
    width: 72px;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
    border-radius: 16px;
}
.nw_profiledp svg{
    position: absolute;
    bottom: -8px;
    right: -8px;
}
.nw_userDetails .profbio{
    font-weight: 400;
    font-size: var(--font-size-13);
    line-height: 19.5px;
    white-space: pre-wrap;
    width: 100%;
}
.nw_userDetails .profname{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.nw_userDetails .profname span{
    color: rgba(125, 125, 141, 1);
    /* font-size: 14px; */
    font-size: var(--font-size-13);
    font-weight: 500;
}
.nw_userDetails .profname h1{
    font-size: 2rem;
    font-weight: 600;
    color: rgba(4, 4, 5, 1);
}
.nw_miniprofile{
    /* height: 390px; */
    min-height: 210px;
    width: auto;
    border: 1px solid rgba(125, 125, 141, 0.16);
    border-radius: 12px;
    padding: 27px;
    display: flex;
    flex-direction: column;
    grid-gap: 24px;
    gap: 24px;
}
.nw_contactrow{
    width: 312px;
    display: flex;
    flex-direction: column;
    grid-gap: 30px;
    gap: 30px;
    /* padding-bottom: 30px; */
    padding: 30px 10px 60px 10px;
}
.nw_contactrow h3{
    font-weight: 600;
    font-size: var(--font-size-20);
}
.nw_contactrow .nw_contactdetails{
    display: flex;
    flex-direction: column;
    grid-gap: 12px;
    gap: 12px;
}
.nw_contact{
    display: flex;
    align-items: center;
    grid-gap: 12px;
    gap: 12px;
}
.nw_contact p, .nw_contact a{
    text-decoration: none;
    font-size: var(--font-size-13);
}
.nw_contactdetails hr{
    border: none;
    height: 1px;
    background-color: #e2e2f0;
}
.nw_storiesscroll{
    min-height: 210px;

}
.nw_storiesscroll > div{
    display: flex;
}
.mw_eventmediacontainer{
    margin-top: 30px;
}

@media (min-width: 400px){
    .nw_miniprofile{
        padding: 27px 10%;
    }
}
@media (min-width: 500px){
    .nw_miniprofile{
        padding: 27px 10%;
    }
}
@media (min-width: 600px){
    .nw_miniprofile{
        padding: 27px 13%;
    }
}
@media (min-width: 664px){
    .nw_profile{
        grid-template-columns: 1fr 312px;
        /* gap: 30px; */
        /* background-color: red; */
    }
    .nw_miniprofile{
        width: 312px;
        padding: 27px;
    }
    .nw_coverphotocontainer{
        grid-column-start: 1;
        grid-column-end: col2-end;
    
    }
    .nw_userDetails{
        grid-column-start: 1;
        grid-column-end: col2-end;
        grid-row-start: 2;
    }
    .nw_miniprofile{
        width: 312px;
        grid-column-start: 2;
        grid-column-end: col2-end;
        grid-row-start: 3;
        /* margin-top: 30px; */
        max-height: 220px;
    }
    .nw_contactrow{
        grid-column-start: 1;
        grid-column-end: col1-end;
        grid-row-start: 3;  
    }
    .nw_storieswrapper{
        margin-top: 30px;
        grid-column-start: 1;
        grid-column-end: col2-end;
        grid-row-start: 4;
    }

    /* .mw_eventmediacontainer{
        grid-row-start: 5;
        grid-column-start: 1;
        grid-column-end: col2-end;
    } */

    .mw_eventmediacontainer{
        grid-row-start: 6;
        grid-column-start: 1;
        grid-column-end: col2-end;
    }

    .nw_ticketwrapper{
        margin-top: 30px;
        grid-column-start: 1;
        grid-column-end: col2-end;
        grid-row-start: 5;
    }
 
}
@media (min-width: 767px){
    .nw_profile{
        /* gap: 30px; */
    }
    .nw_commoncontainer{

        padding: 152px 40px 50px 40px;
    }

    .nw_coverphotocontainer{
        grid-column-start: 1;
        grid-column-end: col2-end;
    
    }
    .nw_userDetails{
        margin-top: 48px;
        /* width: calc(100% - 312px); */
        width: 100%;
        grid-gap: 33px;
        gap: 33px;
        transform: translateY(0px);
        grid-column-start: 1;
        grid-column-end: col2-end;
        grid-row-start: 2;
        /* padding-right: 30px; */
    }
    .nw_user{
        flex-direction: row;
        gap: 30px;
        text-align: left;
        justify-content: left;
        align-items: flex-end;
    }
    .nw_miniprofile{
        margin-top: 48px;
    }
    .nw_contactrow{
        margin-top: 48px;

    }

}
@media (min-width: 1079px){
    .nw_userDetails{

        grid-column-start: 1;
        grid-column-end: col1-end;
        width: calc(100% - 362px);
    }
    .nw_userDetails .profbio{
        padding-right: 10px;
    }
    .nw_miniprofile{
        grid-column-start: 2;
        grid-column-end: col2-end;
        grid-row-start: 2;

    }
    .nw_contactrow{
        margin-top: 30px;
        grid-column-start: 2;
        grid-row-start: 3;
    }
    .nw_storieswrapper{
        width: calc(100% - 362px);
        grid-column-start: 1;
        grid-column-end: col1-end;
        grid-row-start: 3;
    }
    /* .mw_eventmediacontainer{
        grid-row-start: 4;

    } */

    .mw_eventmediacontainer{
        grid-row-start: 5;
    }

    .nw_ticketwrapper{
        margin-top: 30px;
        grid-column-start: 1;
        grid-column-end: col2-end;
        grid-row-start: 4;
    }
}
@media (min-width: 1400px){
    .nw_commoncontainer{
        padding: 152px 14% 50px 14%;
    }
}
@media (min-width: 1920px){
    .nw_profile{
        grid-template-columns: 1fr 312px 312px;

    }
    .nw_userDetails{

        grid-column-start: 1;
        grid-column-end: col1-end;
        width: calc(100% - 662px);
    }
    .nw_miniprofile{
        grid-column-start: 3;
        grid-column-end: col3-end;
        grid-row-start: 2;

    }
    .nw_contactrow{
        margin-top: 30px;
        grid-column-start: 2;
        grid-column-end: col2-end;
        grid-row-start: 2;
        padding-right: 5px;
    }
    .nw_storieswrapper{
        width: calc(100% - 662px);
        grid-column-start: 1;
        grid-column-end: col1-end;
        grid-row-start: 3;
    }
    .nw_coverphotocontainer .nw_coverphotocontainerimg{
        min-height: 380px;

    }
}