.searchHeading{
    display: flex;
    justify-content: space-between;
}
.searchmode{
    display: grid;
    grid-template-columns: 1fr 300px;
  }
  .searchmodediv{
    display: grid;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    padding-top: 20px;
    width: 200px;
  }
  .searchmodebtn{
    border: 1px solid var(--cl_lightgrey);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    width: 150px;
  
    text-decoration: none;
  }
  .searchmodebtn span{
    cursor: pointer;
  }
  .searchmodebtnactive{
    background-color: white;
  }
  .searchmodebtninactive{
    background-color: var(--cl_lightgrey);
  }
  .searchmodebtninactive span{
    color: var(--cl_textgrey);
    font-size: .9rem;
  }
  .searchmodebtnactive span{
    color: black;
    font-size: 1rem;
  }