.featuredProfiles{
    max-width: 688px;
}
.featuredProfilesList{
    width: 100%;
    margin-top: 32px;
    max-width: 688px;
    display: flex;
    flex-direction: column;
    gap: 6px;
}