.singleeventmapscontainer{
    min-height: 100px;
    height: 200px;
    border: 1px solid var(--cl_light_grey);
    border-radius: 12px;
    overflow: hidden;
    width: 312px;
    max-height: 200px;
    text-decoration: none;
    color: black;
    display: grid;
    grid-template-rows: 1fr 1fr;
    transition: all 0.3s ease-in-out;
}
.singleeventmapscontainer:hover{
    box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px; 
}
.maplocation{
    display: flex;
    align-items:center;
    justify-content: center;
    text-align: center;
    padding: 0 20px;
    font-size: 14px;
    font-weight: 600;
    color: rgba(4, 4, 5, 1);
}
.mapimage{
    height: 100px;
    width: 312px;
}
.mapimage img{
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
    height: 100px;
    width: 312px;
}


