.buyticket_eventimage {
	width: 80px;
	height: 80px;
	border-radius: 8px;
	overflow: none;
}

.buyticket_eventimage img {
	width: 80px;
	height: 80px;
	object-fit: cover;
	-o-object-fit: cover;
	object-position: center;
	-o-object-position: center;
	border-radius: 8px;
}

.tikcet__container:hover {
	/* transform:scale(1.005); */
	/* transition: transform 0.3s ease-in-out; */
	background-color: var(--cl_light_grey);
	cursor: pointer;
}

.ticket-grid-container:hover {
	background-color: var(--cl_light_grey);
	cursor: pointer;
}

.ticket-grid-container-head {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	/* number of lines to show */
	line-clamp: 2;
	-webkit-box-orient: vertical;
}

.buytikcet__stage {
	-webkit-animation: fadeChangeEffect 1s;
	animation: fadeChangeEffect 1s;
	position: relative;
	/* transition: transform 1s ease-in-out; */
}

/* .stage__active{
    transform: translateX(0);
}
.stage__inactive{
    transform: translateX(-1200px);
} */

.viewticket__wrapper {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.viewticket {
	position: relative;
	width: 450px;
	height: 200px;
	background: transparent;
	border-radius: 24px;
	/* padding: 16px; */
	/* box-shadow: 0 8px 10px 4px rgba(0, 0, 0, 0.2); */
	/* border: 1px solid var(--cl_light_grey); */
	overflow: hidden;
}

.viewticket::before,
.viewticket::after {
	content: '';
	position: absolute;
	width: 25px;
	height: 25px;
	border-radius: 100%;
	top: 50%;
	transform: translateY(-50%);
	background: #ffffff;
	z-index: 19;
}

.viewticket::before {
	left: -8px;
	box-shadow: inset -3px 0px 4px 0px rgba(0, 0, 0, 0.2);
	z-index: 19;

}

.viewticket::after {
	right: -8px;
	box-shadow: inset 3px 0px 4px 0px rgba(0, 0, 0, 0.2);
	z-index: 19;

}