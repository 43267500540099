
.commentcontainer{
    width: 100%;
    height: auto;

}
.commentsection{
    display: grid;
    grid-template-columns: 50px 1fr;
}
.commentwrapper{
    /* background-color: rgb(255, 184, 184); */
    display: flex;
    flex-direction: column;
}

.dpcontainer{
    width: 32px;
    display: grid;
    grid-template-rows: 32px 1fr;
    padding-top: 10px;
}
.dp{
    width: 32px;
    height: 32px;
    min-width: 32px;
    min-height: 32px;
    border-radius: 50%;
}
.dp img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
}
.comment,.reply{
    display: grid;
    /* grid-template-columns: 50px 1fr; */
}
.commentcontent,.replycontent{
    display: grid;
    grid-template-columns: 32px 1fr 50px;
    /* display: flex; */
    /* padding: 10px 0; */
    gap: 10px;

}
.reply{
    /* margin: 0px 0 0 50px; */
    display: grid;
    grid-template-columns: 50px 1fr;
}

.userdet{
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding: 10px 0;
}
.userdet a{
    font-size: 13px;
    font-weight: 600;
    transition: all 0.3s ease-in-out;
    color: black;
    text-decoration: none;
}
.userdet a:hover{
    text-decoration: underline;
}
.userdet span{
    font-size: 13px;
    font-weight: 400;
}
.iconsbox{
    display: flex;
    gap: 10px;
}
.iconsbox span{
    color: rgb(131, 130, 130);
}
.branch{
    height: 50%;
    width: 50%;
    margin-left: 50%;
    background-color: red;
}
.commentroot{
    height: 100%;
    margin-left: 50%;
    border-left: 1px solid #E2E2F0;
    /* border-bottom-left-radius: 8px; */
}
.replybranch{
    display: grid;
    grid-template-rows: 26px 1fr;



}
.replyroot{
    height: 26px;
    margin-left: 16px;
    margin-right: 16px;
    border-left: 1px solid #E2E2F0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    border-bottom-left-radius: 8px;
}
.replybranches{
    margin-top: 10px;
    margin-left: 16px;
    border-left: 1px solid #E2E2F0;
}
.root{
    display: grid;
    grid-template-rows: 26px 1fr;
}
.rootstart{
    margin-left: 50%;
    height: 26px;
    border-left: 1px solid #E2E2F0;
    border-bottom-left-radius: 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    margin-right: 12px;
}
.rootbranch{
    margin-left: 50%;
    border-left: 1px solid #E2E2F0;
    margin-top: 10px;
}
.viewreplies{
    margin-left: 26px;
    /* color: rgb(131, 130, 130); */
    color: rgba(0, 0, 0);
    font-size: 14px;
}
.viewcomments{
    margin-left: 30px;
    /* margin-top: 10px; */
    /* color: rgb(131, 130, 130); */
    color: rgba(0, 0, 0);
    font-size: 14px;
}