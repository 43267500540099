.business_signup_wrapper{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.business_signup_container{
    max-width: 688px;
    width: 100%;
    /* background-color: var(--cl_bggrey); */
    height: 100%;
    padding: 1vh 10px;
    display: flex;
    flex-direction: column;
    gap: 3vh;

}
.business_signup_header{
    text-align: center;
    line-height: 2rem;
}
.business_signup_header h3{
    font-size: 1.7rem;
}
.business_signup_input_wrapper, .business_signup_input_1{
    height: 100%;
    width: 100%;
}
.business_signup_input_wrapper{
    display: flex;
    justify-content: center;
}
.business_signup_input_1{
    min-width: 400px;
    max-width: 500px;
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.5rem;
}
.business_signup_input{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* line-height: 2rem; */
}
.business_signup_input p{
    display: flex;
    justify-content: space-between;
}
.business_signup_input p small{
    color: red;
}
.business_signup_input input{
    height: 50px;
    width: 100%;
    padding-left: 15px;
    border-radius: 8px;
    border: none;
    /* border: .5px solid var(--cl_textgrey); */
    outline: none;
    background-color: var(--cl_bggrey);
    font-family: 'Inter', sans-serif !important;
}
.business_signup_input input::placeholder{
    font-size: 0.815rem;
    font-family: 'Inter', sans-serif !important;
}
.business_signup_btn{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50px;
    background-color: var(--cl_bggrey);
    color: var(--cl_textgrey);
    font-size: 0.875rem;
    transition: all 0.3s ease-in-out;
    border-radius: 8px;
}
.business_signup_btn_active{
    background-color: var(--cl_orange);
    color: white;
}
.business_signup_btn:hover{
    font-size: 0.9rem;
}
.business_signup_dp_container{
    display: flex;
    justify-content: center;
}
.business_signup_dp{
    height: 100px;
    width: 100px;
    border-radius: 50%;
    background-color: var(--cl_bggrey);
    position: relative;
    overflow: hidden;
}
.business_signup_dp img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
    border-radius: 50%;
}
.business_signup_dp_icon{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;

}
.business_signup_dp_icon svg{
    color: var(--cl_textgrey);
    transition: all 0.3s ease-in-out;
}
.business_signup_dp_icon:hover >svg{
    color: rgb(43, 42, 42);
    transform: scale(1.1);
}