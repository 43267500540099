:root {
	--cl_orange: #ff5917;
	--cl_light_grey: rgba(245, 245, 246, 1);
	--cl_lightgrey: #f5f5f6;
	--cl_lightgrey2: rgba(125, 125, 141, 0.16);
	--cl_textgrey: #7d7d8d;
	--cl_textgrey2: rgba(125, 125, 141, 1);
	--cl_bggrey: #f5f5f6;
	--cl_border_grey: #d7d7e2;
	--cl_hover_orange: #fff2ec;
	--cl_bg-grey: #f5f5f6;
	--cl_light_orange: rgba(255, 89, 23, 0.08);

	  font-size: 16px;
	  font-family: 'Inter', sans-serif;
    
	--font-size-13: 0.8125rem;
	--font-size-14: 0.875rem;
	--font-size-10: 0.625rem;
	--font-size-20: 1.25rem;
	--font-size-12: 0.75rem;
	--font-size-11: 0.685rem;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.wl-featured-gridLayout {
		--auto-grid-min-size: 18rem;
		display: grid;
		grid-gap: 1rem;
		/* place-content: center; */
		place-items: center;
		grid-template-columns: repeat(auto-fill, minmax(var(--auto-grid-min-size), 1fr));
		margin-top: 30px;
		margin-bottom: 30px;
	
		padding: 0;
	}
  }

  

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	scroll-behavior: smooth;
}


.routeinitial {
	height: inherit;
	width: inherit;
}

.pbroute {
	/* position: absolute;
  left: 15px;
  right: 15px; */
	/* transform: translateX(-100px); */
	height: 100%;
}

.pbroute-enter {
	opacity: 0;
	transform: translateX(-100px);
	/* transform: scale(1.03); */
	/* transform: translateX(0px); */
}

.pbroute-enter-active {
	opacity: 1;
	/* transform: scale(1); */
	transform: translateX(0px);
	transition: opacity 300ms, transform 300ms;
}

.pbroute-exit {
	opacity: 1;
	/* transform: scale(1); */
	transform: translateX(-100px);
}

.pbroute-exit-active {
	opacity: 0;
	/* transform: scale(0.97); */
	transform: translateX(100px);
	transition: opacity 300ms, transform 300ms;
}

img {
	font-size: 7px;
}

.logochanged {
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 3px;
}
.logochanged img {
	height: 27px;
}
.logochanged h3 {
	display: flex;
	gap: 2px;
}
.logochanged h3 span {
	font-size: 0.8rem;
	word-spacing: 30px;
}
.logochanged h3 span:nth-child(1) {
	font-size: 0.8rem;
	font-weight: 600;
	font-family: 'M PLUS Rounded 1c', sans-serif;
	transform: translateY(0.005rem);
	color: #727280;
}
.logochanged h3 span:nth-child(2) {
	font-size: 0.8rem;
	font-weight: 400;
	color: #7d7d8d;
}


.bg-green{
	background-color: green !important;
	color: white;
}

.bg-red{
	background-color: red !important;
	color: white;
}