.featuredprofile{
    width: 100%;
    /* height: 50px; */
    background-color: rgba(125, 125, 141, 0.08);
    padding: 18px 16px;
    display: flex;
    gap: 13px;
    align-items: center;
    border-radius: 12px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}
.featuredprofile:hover{
    transform: scale(.98);
    box-shadow: rgba(255, 89, 23, 0.08) 0px 2px 8px 0px;
}
.featuredprofile img{
    height: 32px;
    width: 32px;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
    border-radius: 50%;

}
.featuredNames{
    display: flex;
    flex-direction: column;
    /* min-width: 110px; */
    max-width: 110px;
}
.featuredNames h3{
    width: 100%;
    font-size: var(--font-size-13);
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #040405;
    /* line-height: 24px; */
}
.featuredNames span{
    width: 100%;
    font-size: 11px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: 20px;
    color: #7D7D8D;
}

.featuredprofile .mainBtn{
    /* height: 32px; */
    width: 76px;
    height: 32px;
    margin-left: auto;
    min-width: 76px;
    border-radius: 7px;
    font-weight: 400;
    font-size: var(--font-size-12);
    font-family: "Inter", sans-serif;
}
.featuredprofile .mainBtnInv{
    /* height: 32px; */
    margin-left: auto;
    /* min-width: 70px; */
    cursor: pointer;
    padding: 0 10px;
    /* border: none; */
    min-width: 94px;
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #E2E2F0;
    box-sizing: border-box;
    border-radius: 7px;
}