.invitepeople{
    width: 688px;
    height: 80%;
    background-color: white;
    border-radius: 12px;
    overflow: hidden;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 70px;
    /* flex-direction: column; */
    justify-content: center;
}
.inviteheading{
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.invitepeoplecontent{
    width: 100%;
    /* padding-bottom: 55px; */
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 71px 1fr 70px;
    grid-gap: 16px;
    gap: 16px;
    max-width: 600px;
    justify-self: center;
}
.invitepeoplecontent .invitesect1{
    align-items: center;
}
.invitepeoplecontent .invitesect1 small{
    align-self: flex-start;
}
.invitepeoplecontent .invitesect2{
    justify-content: center;
    overflow: auto;
}
.eventinvitesearch{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 20px;
    position: relative;
    border-radius: 8px;
    background-color: var(--cl_bg-grey);
    padding: 0 10px;
    max-width: 600px;
}
.eventinvitesearch input{
    height: 40px;
    outline: none;
    border: none;
    background-color: var(--cl_bg-grey);

}
.eventinvitesearch svg{
    margin: 13px 3px;
}
.eventinvitelist{
    height: 500px;
    /* width: 100%;
    height: 100%; */
    /* overflow: auto; */
    /* overflow: auto; */
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.eventinvitelist .AddEventUserItem{
    margin-bottom: 8px;
    cursor: pointer;
}
.eventinvitelist .featuredprofile{
    margin-bottom: 8px;
    cursor: pointer;
    margin-right: 20px;
}
.eventinvitebuttons{
    display: flex;
    justify-content: space-between;
}