.net__gross__graph {
    width: 100%;

}

.reportgraph__layout {
    display: grid;
    grid-template-columns: 1fr auto;
    width: 100%;
}

.reportgraph__wrapper {

    display: grid;
    place-content: center;
    place-items: center;
}

.ticketedEvent__layout {
    display: flex;
    /* flex-wrap: wrap; */
    /* max-width: 900px; */
    max-height: 370px;
    width: 100%;
    /* border: solid; */

    /* overflow: auto; */
    /* gap: 15px; */

    /* flex-direction: column; */

}


.TicketedEventItem__wrapper {
    /* height: 380px;    */
    /* padding-bottom:10px ; */
    display: flex;
    /* flex-direction: column; */
    width: 100%;

    /* grid-template-columns:1fr; */
    /* grid-template-rows: 210px 1fr; */
    border-radius: 12px;
    border: 1px solid rgba(125, 125, 141, 0.16);
    /* overflow: hidden; */
    transition: box-shadow 0.3s ease-in-out;
    cursor: pointer;
    padding: 10px;

}

.TicketedEventItem__wrapper:hover {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.TicketedEventItem__Image {
    /* width: 100%; */
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.TicketedEventItem__Image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.TicketedEventItem__details__wrap {
    display: flex;
    /* flex-direction: column; */
    padding: 10px;
    gap: 5px;
}

.TicketedEventItem__details {
    display: flex;
    flex-direction: column;

    /* justify-content: space-between; */
    font-size: .90rem;
    font-weight: 500;
    /* align-items: center; */
}

.TicketedEventItem__gross {
    display: flex;
    flex-direction: column;
    font-size: .90rem;
    font-weight: 600;
    gap: 5px;

}

.ticketedEvent__layout table {
    border: none;
    border-collapse: collapse;
    /* width: 100%; */
}


/* .ticketedEvent__layout td,
th {
    /* min-width: 180px;
    width: 100%;
    text-align: left;
    border-top: 1px solid var(--cl_lightgrey);
    height: 40px; */
/* border: ; */

/* } */

/* 
.ticketedEvent__layout th {

    border-top: 1px solid var(--cl_lightgrey);


} */

.pending_payments {
    display: flex;
    width: 100%;
    flex-direction: column;
    max-width: 30em;
    /* max-height: 220px;
    min-height: 150px; */
    border: 1px solid var(--cl_lightgrey);
    border: 10px;
    padding: 20px;
    /* margin: 10px; */
    /* justify-content: flex-end; */
    /* flex-direction: column; */
    /* justify-items: center; */
    border-radius: 12px;
    font-family: Inter, sans-serif;
    /* flex-grow: 2; */
    /* overflow: scroll; */
    /* justify-content: space-between;
    position: absolute; */
    gap: 10px;


}

.pending_payment_div {

    width: 100%;
    height: 100%;
    max-width: 30em;
    min-width: 30em;
    /* border: solid 3px red; */
    padding: 20px;
    border-radius: 12px;
    border: 1px solid var(--cl_lightgrey);
    font-family: Inter, sans-serif;
    gap: 10px;

}

.pending_payments_event {
    display: flex;
    /* justify-content: space-between; */
    /* align-items: center; */
    border-bottom: 1px solid var(--cl_lightgrey);
    /* justify-content: space-between; */
    /* gap: 20px; */
    gap: 10px;

}

.pending_payment_event_title {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}



.pending_amount {
    padding: 10px;
}

.pending_payments_head {
    display: flex;
    border: 10px;
    justify-content: space-between;
}

.pending_payments_image {
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-width: 30px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    overflow: hidden;
}

.pending_payments_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.pending_payments_title_amount {
    /* border:solid 1px; */
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;

}

.recent_payouts {
    display: flex;
    width: 42%;
    flex-grow: 2;
    /* max-height: 220px; */
    /* min-height: 150px; */
    /* max-width: 30em; */

    border: 1px solid var(--cl_lightgrey);
    padding: 20px;
    gap: 10px;
    /* margin: 10px; */
    /* justify-content: flex-end; */
    flex-direction: column;
    /* justify-items: center; */
    border-radius: 12px;
    font-family: Inter, sans-serif;
    /* overflow: scroll; */
    /* justify-content: space-between; */


}

.recent_payout_image {
    max-width: 30px;
    min-width: 30px;
    max-height: 30px;
    min-width: 30px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
    overflow: hidden;

}

.recent_payout_image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.recent_payout_head {
    display: flex;
    align-items: center;
    grid-auto-flow: column;
    /* grid-column-gap: 120px; */
    justify-content: space-between;
    /* row-gap: 30; */

}

.recent_payout_title_amount {
    /* border:solid 1px; */
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    /* flex-direction: column; */


}

.recent_payouts_event {
    display: flex;
    /* justify-content: space-between; */
    /* align-items: center; */
    border-bottom: 1px solid var(--cl_lightgrey);
    /* justify-content: space-between; */
    /* gap: 20px; */
    gap: 5px;
}

.recent_ayout_head {
    display: flex;
    /* justify-content: space-between; */

}



.recent_payout_event_title {
    width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ticketing_container {
    display: flex;
    width: 100%;
    /* max-width: 555em; */
    /* /* flex-grow: 1;  */
    /* max-width: 15em; */
    min-width: 15em;
    /* height: 2rem; */
    max-height: 220em;
    min-height: 160px;
    border: 1px solid var(--cl_lightgrey);
    padding: 20px;
    margin-bottom: 20px;
    /* margin: 10px; */
    /* justify-content: center; */
    flex-direction: column;
    border-radius: 12px;
    font-family: Inter, sans-serif;

}

.ticketing_container_data {
    justify-content: flex-start;
}

.row_ticketing_container {
    display: flex;
    /* justify-content: space-between; */
    /* width: 50%; */
    width: 70%;
    height: 200px;
    /* flex-grow: 1; */
    /* flex-wrap: wrap; */
    gap: 10px;


}

/* @media(min-width:30em) {
    .row_ticketing_container {
        display: flex;
    }
} */

.column_ticketing_container {
    display: flex;
    /* flex-direction: column; */
    /* width: 100%; */
    width: 100%;
    gap: 20px;

    /* padding: 20px 0px; */

}

.ticket_container_heading_row {

    display: flex;
    align-items: baseline;
    grid-auto-flow: column;
    /* grid-column-gap: 120px; */
    justify-content: space-between;
}

.ticket_event {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    /* justify-content: space-between; */
    /* align-content: space-between; */
    flex-wrap: wrap;
    margin-top: 10px;


}

/* @media(max-width:30em) {
    .ticket_event {
        flex-direction: column;
    }
} */


.ticket_event_column1 {
    display: flex;
    flex-grow: 1;
    gap: 20px;
    padding-bottom: 20px;
    /* width: 100%; */

    /* flex-direction: column; */
    /* height: 85vh; */

}

.ticket_event-column2 {
    display: flex;
    /* flex-direction: column; */
    /* width: 100%; */
    width: 100%;
    height: 300px;
    gap: 10px;


}


.scroll_bar {
    height: 250px;
}

.ticketing_container_data {
    padding: 30px;
    height: 50px;
}

.table_data_content {
    display: flex;
    /* align-items: center; */
    padding-left: 7px;
    width: 100%;
    height: 50px;
    align-items: center;


    justify-items: flex-start;
    justify-content: space-between;
    border-bottom: solid 1px rgb(227, 235, 238);
}

.table_data_content td {
    width: 100%;
    align-items: center;


}

.home_events_name_scroll_list_name {
    display: flex;
    /* width: 85px; */
    width: 100%;
    align-items: center;

    justify-content: space-between;
    background-color: aqua;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: clip;
}

.thead-home {
    display: flex;
    height: 40px;
    background-color: lightgray;
    justify-content: space-between;
    justify-items: flex-start;
    align-items: center;
    align-content: flex-start;
    /* justify-content: flex-start; */
    /* align-items: flex-start; */
    /* align-items: flex-start; */
    padding-left: 7px;
    border-radius: 5px;
    /* border: solid 1px; */

}

.thead-home h5 {
    width: 100%;
    /* justify-content: flex-start; */
    /* border: solid 1px red; */
    /* align-content: flex-start;
    justify-content: flex-start; */
}

.cm_detail_table {
    width: 100%;
    border-radius: 5px;

    /* min-height: 200px;
    max-height: 30px; */
    border: solid 5px lightgray;
    /* height: 300px; */
}

.TicketedEventItem__name {
    display: flex;
    gap: 10px;
    align-items: center;
}

/* .table_content_datas {
    border: solid 1px;
    display: flex;
    justify-items: center;
} */