.customrolemodal li{
    transition: background-color 0.2s ease-in-out;
    border-radius: 8px;
    /* padding: 15px 20px; */

    padding: 12px 16px;
    text-transform: uppercase;
}
.cutomrolesinput{
    padding-left: 15px;
}
.customrolesinputwrapper{

}
.customrolesinputwrapper button{
    border: none;
    height: 40px;
    border-radius: 0 7px 7px 0;
    background-color: var(--cl_orange);
    color: white;
    font-family: "Inter", sans-serif;
    cursor: pointer;

}
.customroleitem{
    display: grid;
    grid-template-columns: 1fr 20px;
    /* cursor: pointer; */
}
.customroleitem:hover{
    background-color: var(--cl_light_orange);
}
.customroleicon{
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 1px solid var(--cl_orange);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    visibility: hidden;
    transition: visibility 0.3s ease-in-out;

}
.customroleicon svg{

    color: var(--cl_orange);

}
.customroleitem:hover .customroleicon{
    visibility: visible;
}