.payout__container {
    /* border: 1px solid red; */
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

}


.payout__account__container {
    display: flex;
    /* flex-direction: column; */
    gap: 10px;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px 0px;

    /* bottom extra added */

}

.payout_card_1 {
    height: 300px;
    width: 70%;
    padding: 20px;
    flex-direction: column;
    gap: 5px;
    background-color: rgb(235, 219, 206);
    border-radius: 12px;

}

.payout_card_1 div {
    display: flex;
    justify-content: space-between;
    height: 40px;
    border-bottom: solid 1px lightgray;

}

.payout_card_1 p {
    opacity: .5;
}

.payout__account__wrapper {
    /* border: 1px solid red; */
    width: calc(50% - 5px);
    padding: 20px;
    display: flex;
    border: 1px solid var(--cl_lightgrey);
    background-color: rgb(235, 219, 206);
    border-radius: 15px;
    height: 10rem;

}

.payout__account__details {

    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 5px;

}

.payout_card_1_buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
}

.payout__account__details>p {

    width: calc(50% - 5px);
    text-align: left;
    font-weight: 500;

    /* border: 1px  solid rgb(0, 38, 255); */



}

.payout__account__details>p:nth-child(even) {

    text-align: right;
    /* border: 1px  solid red; */
    font-weight: 600;

}


.payout__account__dp {

    width: 50px;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.payout__account__dp img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.payout__account__actions {

    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    gap: 10px;
    padding-left: 15px;
    /* align-self: flex-end; */
}

.payout__add__modal {
    display: flex;
    flex-direction: column;
    padding: 30px 40px;
    gap: 30px;
}




.payout__add__modal__inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.payout__add__modal__inputs>div {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.payout__add__modal__inputs input {
    height: 40px !important;
    width: 350px !important;
    /* margin-left: 58px; */
    padding: 0 15px;
    border: none;
    outline: none;
    background-color: var(--cl_bggrey);
    border-radius: 8px;
    font-family: 'Inter', sans-serif;
    outline: none;
}

.payout__add__modal__inputs label {
    display: flex;
    flex-direction: row;
    font-size: .90rem;
    font-weight: 500;
    text-align: left;
}

.delete_modal_payout {
    height: 170px;
    width: 300px;
    flex-direction: column;
    background-color: white;
    /* border-radius: 15px; */
    padding: 40px;
    gap: 30px;
    justify-items: center;
    border: 10px;
}

.delete_modal_payout h4 {
    margin-left: 10px;
    margin-bottom: 10px;
}

.delete_modal_button {
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 50px;
    border: 10px;
    gap: 20px;

}

.delete_modal_button button {
    background-color: var(--cl_bggrey);
    border: none;
    /* color: white; */
    padding: 15px 32px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
    border-radius: 12px;

}