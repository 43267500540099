/* .introOverlay img{
    height:400px;
    width: 400px;
} */
/* .introOverlay .dot{

} */
.carouselContent{
    height: 100%;
    width: 100%;
    /* padding: 0 40px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    position: relative;
}
.carouselContent .slidemain{
    object-fit: contain;
    /* height: 510.5px; */
    height: 55vh;
    width: 323.5px;
    /* transform: scale(.5); */
    z-index: 10;
    /* position: absolute; */
}
.carouselDescription {
    width: 80%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 24px;
    color: white;
    /* padding-top: 100px; */
    /* padding: 0 40px; */
}
.carouselDescription h3{
    /* font-size: 1.5rem; */
    font-weight: 500;
    font-size: 1.3rem;

}
.carouselDescription span{
    font-size: var(--font-size-13);
    font-weight: 300;
    color: #ffffffe1;
    line-height: 1.5rem;
}
.loginbg{
    opacity: 0.7;
}

.slide31,.slide32,.slide33,.slide34{
    position: absolute;
    z-index: 5 !important;
}
.slide31{
    transform: scale(.5) translate(-15vw,-42vh);
    
    z-index: 11 !important;
    /* display: none !important; */
}
.slide32{
    transform: scale(.5) translate(15vw,25vh);
    z-index: 11 !important;
}
.slide33{

    transform: scale(.5) translate(15vw,-30vh);
}
.slide34{
    transform: scale(.4) translate(-20vw,-10vh);
}
.slide41, .slide42{
    position: absolute;
    z-index: 5 !important;
}
.slide41{
    transform: scale(.25) translate(25vw,-85vh);
}
.slide42{
    transform: scale(.25) translate(-25vw,15vh);
}