.EventMediaLayout{
    position: relative;
}
.EventMediaLayout .menuContainer{
    padding-top: 40px;
    /* padding-bottom: 1px; */
    position: sticky;
    position: -webkit-sticky;
    top: 106px;
    background-color: white;
    z-index: 100;
    /* box-shadow: rgba(0, 0, 0, 0.2) 0px 60px 40px -7px; */
    box-shadow: rgba(0, 0, 0, 0.041) 0px 25px 15px -20px;
}
