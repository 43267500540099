.chatpopup {
  height: 464px;
  width: 336px;
  position: absolute;
  background: #ffffff;
  border-radius: 12px;
  top: -498px;
  right: -28px;
  box-shadow: 0px 0px 1px rgba(125, 125, 141, 0.5), 0px 34px 40px rgba(125, 125, 141, 0.16);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  overflow: hidden;

  display: flex;
}

.chatpopup.inactive {
  opacity: 0;
  visibility: hidden;
  transform: translateY(20px);
}

.chatpopup.active {
  opacity: 1;
  visibility: visible;
  /* transform: translateY(0); */
}





.minichatmain {
  position: relative;
  height: 100%;
  /* width: 100%; */
  width: 336px;
  min-width: 336px;
  transition: transform 0.3s ease-in-out;
}

.minichatmainactive {
  transform: translateX(0);
}

.minichatmaininactive {
  transform: translateX(-336px);
}

.minichatheader {
  height: 72px;
  width: 100%;
  /* position: sticky; */
  top: 0;
  box-shadow: inset 0px -1px 0px #E2E2F0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px 0 24px;
}

.minichatheader h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #040405;
}

.whiteiconwrapper {
  height: 40px;
  width: 40px;
  border: 1px solid #E2E2F0;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

}

.whiteiconwrapper svg {
  stroke: black;
}

.whiteiconwrapper:hover {
  background-color: rgba(255, 89, 23, 0.08);
  border: 1px solid rgba(255, 89, 23, 0.08);
}

.whiteiconwrapper:hover svg {
  stroke: #FF5917;
}

.minichatsearch {
  height: 72px;
  box-shadow: inset 0px -1px 0px #E2E2F0;
  padding: 16px 24px;
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
  width: 100%;
}

/* .minichatsearchactive{
    height:72px;
    transform: translateY(0);
    opacity: 1;
    visibility: visible;
}
.minichatsearchinactive{
    height:0;
    transform: translateY(-73px);
    opacity: 0;
    visibility: hidden;
} */
.minichatinputwrapper {
  height: 100%;
  width: 100%;
  background: #F5F5F6;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr 14px;
  gap: 10px;
  align-items: center;
  padding: 0 16px;
}

.minichatinputwrapper input {
  border: none;
  background: #F5F5F6;
  border-radius: 8px;

  height: 100%;

}

.minichatinputwrapper svg {
  stroke: #7D7D8D;
}

.chatnotifications {
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 336px;
  transition: transform 0.3s ease-in-out;
}

.minichatmainactive {
  transform: translateX(0);
}

.minichatmaininactive {
  transform: translateX(-336px);
}

.chatnotificationsheader {
  height: 72px;
  width: 100%;
  position: sticky;
  top: 0;
  box-shadow: inset 0px -1px 0px #E2E2F0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px 0 24px;
}

.chatnotificationshead {
  display: flex;
  align-items: center;
  gap: 16px;
}

.chatnotificationshead h4 {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #040405;
}

.minichatuserlist {
  height: 320px;
}

.minichatuser {
  height: 80px;
  min-height: 80px;
  max-height: 80px;
  display: flex;
  background-color: white;
  cursor: pointer;
  box-shadow: inset 0px -1px 0px #E2E2F0;
}

.minichatuseractive {
  /* height: 80px; */
  min-height: 80px;
  /* max-height: 80px; */
  display: grid;
  grid-template-columns: 40px 1fr;
  padding: 20px 20px 20px 24px;
  gap: 16px;
  background-color: #FFF2EC !important;
  cursor: pointer;
  border-bottom: 1px solid #E2E2F0;
}

/* .minichatuser:hover{
    background-color: #F5F5F6 !important;
  } */
.minichatuserleft {
  width: 192px;
  min-width: 192px;
  height: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 20px 16px 20px 24px;
  transition: transform 0.3s ease-in-out;
}

.minichatuserleftactive {
  transform: translateX(0);
}

.minichatuserleftinactive {
  transform: translateX(-192px);
}

.minichatuserleft .whiteiconwrapper {
  width: 32px;
  height: 32px;
}

.minichatuserleft .whiteiconwrapper:hover {
  background: rgba(255, 89, 23, 0.08);
  border: 1px solid rgba(255, 89, 23, 0.08);
}

/* .minichatuserleft .whiteiconwrapper:hover svg{
    color: #FF5917;
    stroke: #FF5917;
    clip-path: #FF5917;
    
} */


.minichatuserright {
  /* display: grid; */
  /* grid-template-columns: 40px 1fr auto; */
  /* padding: 20px 24px 20px 24px; */
  /* gap: 16px; */
  transition: transform 0.3s ease-in-out;
  /* align-items: center; */
  min-width: 334px;
}

.minichatonlinestat {
  width: 8px;
  height: 8px;
  background-color: #FF5917;
  border-radius: 50%;
  /* align-self: flex-end; */
}

.minichatuserrightactive {
  /* width: 336px; */
  transform: translateX(-192px);
}

.minichatuserrightinactive {
  transform: translateX(0);

}

.minichatuser img {
  min-width: 40px;
  max-width: 40px;
  max-height: 40px;
  min-height: 40px;

  border-radius: 50%;
}

.minichatrecentdetails {
  font-size: 14px;
  line-height: 20px;
  color: #040405;
  margin-left: 16px;
  margin-right: 8px;
}

.minichatrecent {
  display: grid;
  grid-template-columns: 1fr 50px;
  gap: 16px;
}

.minichatrecent span {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 12px;
  line-height: 20px;
  color: #7D7D8D;
}

.minichatrecent small {
  display: flex;
  margin-top: auto;
  font-size: 11px;

}


.minichatuserchat {
  min-width: 336px;
  height: 100%;
}

@keyframes chatslidein {
  from {
    transform: translateX(336px);
  }

  to {
    transform: translateX(0);
  }
}

.miniuserchatmessages {
  position: relative;
  height: 100%;
  width: 100%;
  min-width: 336px;
  transition: transform 0.3s ease-in-out;
  /* position: fixed;
      height: 464px;
      width: 336px;
      bottom: 0;
      right: -336px;
      background: #ffffff;
      border-radius: 12px;
      overflow: hidden;
      transition: transform 0.3s ease-in-out; */

}

.miniuserchatmessagesactive {
  transform: translateX(-672px);
}

.miniuserchatmessagesinactive {
  transform: translateX(-336px);
}

.miniuserchatmessageswrapper {
  position: relative;
  width: 100%;
  height: 100%;
  /* background-color: cornsilk; */
  display: grid;
  grid-template-rows: 72px 312px;
}

.minichatmessagesheader {
  padding: 16px 24px;
  height: 74px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: inset 0px -1px 0px #E2E2F0;
}

.minichatmessagesuser {
  display: flex;
  gap: 16px;
  height: 100%;
}

.minichatmessagesuser img {
  min-width: 40px;
  max-width: 40px;
  max-height: 40px;
  min-height: 40px;

  border-radius: 50%;
}

.svgcover {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.minichatmessageuserdetails h4 {
  font-size: 14px;
  line-height: 20px;
  color: #040405;
}

.minichatmessageuserdetails span {
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  color: #7D7D8D;
}

.miniuserchatinputmessages {
  position: sticky;
  position: -webkit-sticky;
  display: flex;
  flex-direction: column-reverse;
  gap: 16px;
  width: 336px;
  /* height: 80px; */

  bottom: 0;

  background: #FFFFFF;
  box-shadow: inset 0px 1px 0px #E2E2F0;
  border-radius: 0px 0px 12px 12px;
  padding: 20px 24px;

}

.miniuserchatinputmessagesactive {
  height: 132px;
}

.miniuserchatinputmessagesinactive {
  height: 80px;
}

/* .miniuserchatinputmessages .minichatinputwrapper{
    display: grid;
    grid-template-columns: 1fr 68px;
  } */
.miniuserchatinputwrapper {
  /* height: 100%;
    width: 100%; */
  width: 288px;
  height: 40px;
  background: #F5F5F6;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr 14px 14px;
  grid-gap: 10px;
  gap: 10px;
  align-items: center;
  padding: 0 16px;
  align-self: flex-end;
}

.miniuserchatinputwrapper input {
  height: 100%;
  border: none;
  outline: none;
  background: #F5F5F6;
}

.miniuserchatinputicons {
  width: 100%;
  transition: all 0.3s ease-in-out;
}

.miniuserchatinputiconsactive {
  display: flex;
  justify-content: space-between;
}

.miniuserchatinputiconsinactive {
  height: 0;
  display: none;
}

.miniuserchatinputicons .whiteiconwrapper {
  width: 32px;
  height: 32px;
}

.miniuserchatinputiconsleft {
  display: flex;
  gap: 8px;
}

.miniuserchatinputiconsright {
  display: flex;
  gap: 8px;
}




.datetab_chat {
  width: 100%;
  padding: 10px 24px;
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  align-items: center;
  justify-content: center;
  color: var(--cl_bggrey);

}

.datetab_chat hr {
  width: 100%;
  border: 1px dashed;
  border-style: none none dashed;
  color: var(--cl_bggrey);

}

.datetab_chat span {
  width: auto;
  padding: 5px;
  color: var(--cl_textgrey);
  background-color: var(--cl_bggrey);
  font-size: .6rem;
  border-radius: 5px;
}

.miniuserchatcontent>div {
  display: flex;
  flex-direction: column-reverse;
  gap: 5px;
  padding: 5px 0;
}

.sendermessagecontainer {
  position: relative;
  width: 100%;
  padding: 0 24px;
  max-width: 248px;
  overflow-wrap: break-word;
}

.sendermessagecontainer3 {
  display: flex;
  gap: 16px;
  position: relative;
  width: 100%;
  padding: 0 24px;
  max-width: 312px;
  overflow-wrap: break-word;

}

.sendermessagecontainer3 p,
.sendermessagecontainer p {
  /* display: flex;
  flex-direction: row;
  align-items: flex-start; */
  /* width: 100%; */
  max-width: 100%;
  display: inline-block;
  padding: 12px 16px;
  /* width: auto; */

  /* height: 84px; */
  background: #FFFFFF;
  border: 1px solid #E2E2F0;
  border-radius: 12px 12px 12px 0px;
}

.repliermessagecontainer {
  position: relative;
  width: 100%;
  padding: 0 24px;
  max-width: 248px;
  overflow-wrap: break-word;
  align-self: flex-end;
  display: flex;
  align-content: flex-end;
  flex-direction: column;

}

.repliermessagecontainer p {
  max-width: 100%;
  display: inline-block;
  padding: 12px 16px;
  background: #FF5917;
  border-radius: 12px 12px 4px 12px;
  color: white;
  margin: 0 0 0 auto;

  /* float: right; */
}

.chatmessageoptions {
  position: absolute;
  box-shadow: 0 1px 8px rgb(0 0 0 / 30%);
  min-width: 200px;
  border-radius: 10px;
  background-color: white;
  /* bottom: 0; */
  z-index: 2000;
}

.repliermessagecontainer .chatmessageoptions {
  top: 40px;
}

.chatmessageoptions span {
  position: absolute;
  top: -6px;
  right: 20px;
  /* left: 20px; */
  display: block;
  height: 10px;
  width: 10px;
  background-color: white;
  border-left: 2px solid rgba(0, 0, 0, 0.1);
  border-top: 2px solid rgba(0, 0, 0, 0.1);
  transform: rotate(45deg);
}

.repliermessagecontainer .chatmessageoptions span {
  right: 20px;
}

.sendermessagecontainer .chatmessageoptions span {
  left: 20px;
}

.chatmessageoptions ul {
  list-style: none;
  padding: 5px;
}

.chatmessageoptions li {
  padding: 15px 20px;
}

.chatmessageoptions li:hover {
  color: var(--cl_orange);
  background-color: var(--cl_light_orange);
  border-radius: 8px;
}

.whiteiconwrapper .settingsDropdown {
  right: 0 !important;
}

.whiteiconwrapper .settingsDropdown span {
  top: -6px !important;
  right: 20px !important;
}

@media (min-width: 1400px) {
  .whiteiconwrapper .settingsDropdown span {
    top: -6px !important;
    right: 20px !important;
    left: unset !important;
  }
}

.minichaticonpicker aside.emoji-picker-react {
  width: 336px !important;
  height: 300px !important;
  transform: translateX(-25px) translateY(20px);
}