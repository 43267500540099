/* .carousel__story__page .slide, */
/* .carousel__story__page .slider-wrapper {
	height: auto !important;
} */

/* .carousel__story__page .selected {
    transform: translateY(-10px) !important;
    transition: transform 1s ease-in-out;
} */
.carousel__story__page{
    /* width: 600px; */
    border: 1px solid rgb(46, 211, 24);
    width: 95%;
    height: 100vh !important;
}
 
.carousel__story__page {
    height: 100% !important;
    max-height: 100vh;

}
.slick-track {
    /* height: 100% !important; */
    /* max-height: 100vh; */
    /* border: 3px solid red; */
}
.slick-list{
    padding-top:100px;
}
.story__item{
   min-width : 450px;
   max-width: 450px;

    height : 900px;
   background-color: black;
   border-radius: 20px;
   overflow: hidden;

}
.story__item img{
    object-fit: cover;
    width : 100%;
    height : 100%;
}
.story__item__selected{
    min-width : 450px;
    max-width: 450px;

    height : 900px;

   background-color: black;
   border-radius: 20px;
   overflow: hidden;

}

.story__item__selected img{
    object-fit: cover;
    width : 100%;
    height : 100%;

}
.story__item__selected video{
    object-fit: cover;
    width : 100%;
    height : 100%;

}


.storyitem__tile__image {
	/* width: 100%; */
	/* height: 100%; */
	/* background-color: rgba(125, 125, 141, 0.08); */
	padding: 10px;
	display: flex;
	/* gap: 13px; */
	align-items: center;
	border-radius: 12px;
	cursor: pointer;
	transition: transform 0.3s ease-in-out;
}
.storyitem__tile__image img:hover {
	transform: scale(1.1);
	/* box-shadow: rgba(255, 89, 23, 0.08) 0px 2px 8px 0px; */
}
.storyitem__tile__image img {
	height: 45px;
	width: 45px;
	object-fit: cover;
	-o-object-fit: cover;
	object-position: center;
	-o-object-position: center;
	border-radius: 50%;
	transition: transform 0.3s ease-in-out;
	box-shadow: rgba(255, 89, 23, 0.08) 0px 2px 8px 0px;

}
.storyitem__tile__name{
    display:flex;
    flex-direction: column;
    align-items: flex-start;

}
.storyitem__tile__name p{
    color: white;
    font-weight: 500;
    
}

.storyitem__tile__name span{
    color: white;
    font-weight: 500;
    opacity: .5;
}


.mySwiper{
    /* border : 1px solid red; */
    /* position: relative; */
}

.swiper__next{


    position: absolute;
    top: 50%;
    right: -10%;
    background-color:white;
    z-index: 100;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: .3;



    /* width: 100%; */

}

.swiper__prev{
    position: absolute;
    top: 50%;
    left: -10%;
    background-color:white;
    z-index: 100;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: .3;
    

    /* background-color:red; */
}
.swiper__prev:hover{
    opacity: 1;
    cursor: pointer;
}

.swiper__next:hover{
opacity: 1;
cursor: pointer;
}

/* class="swiper-slide swiper-slide-active" */

/* .mySwiper .swiper-slide-active{
    transform:  scale(1.25);
    transition: transform 1s ease-in-out;
} */




/*  another style story */

/* .mySwiper .swiper-slide {
    transition:all 200ms linear;
    transform: scale(0.6) !important;
    
  }
  .swiper-slide.swiper-slide-active{
    transform: scale(1) !important;

  } */


/*  another style story */






/* .mySwiper .swiper-slide{
    transform:  scale(.6);
    transition: transform 1s ease-in-out;
    border: 1px solid red;
} */


/* .story__item__selected img{
    object-fit: contain;
    width : 100%;
    height : 100%;
} */

/* class="slick-slide slick-active slick-center slick-current" */

.carousel__story__page .slick-active{
    transform:  scale(.55);
    transition: transform 1s ease-in-out;

}
.carousel__story__page .slick-slide{
    transform:  scale(.55);
    transition: transform 1s ease-in-out;
    /* border: 1px solid red; */
}
/* class="slick-slide slick-active slick-center slick-current" */
/* .carousel__story__page .slick-center {
        transform:  scale(.55);
        transition: transform 1s ease-in-out;
  
    } */