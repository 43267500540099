.landingpagelayout {
	width: 100%;
	position: relative;
	color: white;
	display: flex;
	flex-direction: column;
	gap: 0;
	overflow-x: hidden;
}
.landingpagefirstsec {
	width: 100%;
	height: 700px;
	min-height: 700px;
	background: linear-gradient(/* 200deg, */ #ff5917 0%, #ffa617 54.83%, #ffa617 85%, #9208ff 120%);
}
.landingpageheader {
	width: 100%;
	height: 50px;
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	padding: 0 20px;
}
.landingpagelogo {
	height: 24px;
	width: 36px;
	/* height: 32px;
    width: 49px; */
	object-fit: contain;
}
.landingpagelogo img {
	object-fit: contain;
	width: 100%;
	height: 100%;
}
@keyframes Open {
	from {
		height: 100px;
		opacity: 0;
		width: 100px;
	}
	to {
		height: 100vh;
		opacity: 1;
		width: 100%;
	}
}
.landingpagemenu {
	position: fixed;
	/* width: 100%; */
	/* height: 100vh; */
	top: 0;
	right: 0;
	background-color: black;
	z-index: 900;
	overflow: hidden;
	overflow-y: hidden;
	overflow: -moz-hidden-unscrollable;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 12% 4%;
	/* justify-content: center; */
	animation: Open 0.3s linear forwards;
}
.landingpagemenu_links {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	padding: 10vh 10px;
	/* margin: 5vh 0; */
	height: 50vh;
}
.landingpagemenu_links a {
	text-decoration: none;
	color: white;
	text-align: center;
	font-size: 1.2rem;
	text-transform: uppercase;
}
.landingpagemenuicon {
	display: flex;
	flex-direction: column;
	gap: 5px;
	width: 30px;
	height: 40px;
	padding: 12px 4px;
	cursor: pointer;
	z-index: 1000;
	transform: translateY(12px);
}
.menubar1 {
	width: 11px;
	height: 1px;
	align-self: flex-end;
	background-color: white;
	transition: 0.3s;
	border-radius: 0.5px;
}
.menubar2 {
	width: 22px;
	height: 1px;
	background-color: white;
	border-radius: 0.5px;
}
.menubar3 {
	width: 11px;
	height: 1px;
	align-self: flex-start;
	background-color: white;
	border-radius: 0.5px;
}
.menubar1open {
	height: 1px;
	/* align-self: flex-end; */
	background-color: white;
	transform: translate(0px, 5px) rotate(-45deg);
	width: 22px;
	transition: 0.3s;
	border-radius: 0.5px;
}
.menubar3open {
	height: 1px;
	align-self: flex-end;
	background-color: white;
	transform: translate(0px, -5px) rotate(45deg);
	width: 22px;
	transition: 0.3s;
	border-radius: 0.5px;
}
.firstsecheading {
	margin-top: 50px;
	color: white;
	padding: 10px 15px;
	display: flex;
	flex-direction: column;
	gap: 10px;
}
.firstsecheading h1 {
	font-weight: 600;
	font-size: 43px;
}
.firstsecheading h2 {
	font-weight: 400;
	font-size: 43px;
}
.landingpagelayout h3 {
	font-weight: 400;
	font-size: 16px;
	line-height: 24px;
}
.firstsecimages {
	display: flex;
	justify-content: space-between;
	/* border: 2px solid rgb(133, 133, 18); */
	/* transform: translateY(10vw); */
}
.first1,
.first3 {
	display: none;
}
.first2 {
	margin: 0 auto;
	height: 360px;
	width: 290px;
}
.first2 img {
	width: 100%;
	height: 100%;
}

.cr_images {
	margin: 0 auto;
	height: 435px;
	width: 95%;
	transform: translateY(30px)
	/* border : 1px solid blue; */
	/* padding:0px 10px; */
}
.cr_images img {
	width: 100%;
	height: 100%;
	object-fit: contain;
	/* border : 1px solid blue */
}

.landingpagesecondsec {
	background-color: #f1f1f1;
	/* min-height: 700px; */
	width: 100%;
	/* padding-top:calc(18vh + 100px); */
	padding-top:calc(15vh + 100px);

}

@media (max-width: 458px) {
	.landingpagesecondsec {
		background-color: #f1f1f1;
		/* min-height: 700px; */
		width: 100%;
		/* padding-top: 24vh; */
	padding-top:calc(15vh + 100px);

	}
}
.landingpagedetect {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	color: var(--cl_light_grey);
	padding: 10px 0;
}
.landingpagesecondsec h4,
.landingpagesecondsec .firstsecheading h3 {
	color: black;
}
.landingpagesecondimages {
	display: flex;
	/* gap: 10px; */
	/* margin-top: 20px; */
	justify-content: space-between;
	align-items: flex-start;

	/* overflow-x: hidden; */
	overflow-x: visible;

	overflow-y: visible;

	min-height: 100%;
	/* border: 1px solid rgb(29, 223, 223); */
}

.second1 {
	/* margin-left: auto;
 -webkit-animation: fadeChangeEffect .5s;
 animation: fadeChangeEffect .5s; */
}
.second2 {
	/* margin-left: auto;
transform: translateX(15px) translateY(50px);
z-index: 10;
-webkit-animation: fadeChangeEffect .5s;
animation: fadeChangeEffect .5s; */
}

.selected {
	/* border: 1px solid red !important; */
	/* -webkit-animation: fadeChangeEffect 3s !important;
	animation: fadeChangeEffect 3s !important; */

	/* -webkit-animation: fadeOff 5s !important;
	animation: fadeOff 5s !important; */
}
.slide {
	/* -webkit-animation: fadeOff 3s !important;
	animation: fadeOff 3s !important; */
	/* opacity: 0.05 !important; */
	transition: transform 1s ease-in-out;
}
/* .animated{
	border: 1px solid red !important;
      -webkit-animation: fadeOff 3s !important;
	animation: fadeOff 3s !important;

} */

.slide,
.slider-wrapper {
	height: auto !important;
}

/* new slide style */

.new__slide {
	/* border: 1px solid red; */
	/* width:70vw; */
	height: 560px;
	display: flex;
	align-items: flex-end;
	padding: 10px 0px;
	margin-left: -100px;
}

.new__slide2 {
	height: 560px;
	display: flex;
	align-items: flex-end;
	padding: 10px 0px;
	margin-right: -100px;
}

@media (max-width:370px){

	.new__slide {
		/* border: 1px solid red; */
		/* width:70vw; */
		height: 450px;
		display: flex;
		align-items: flex-end;
		padding: 10px 0px;
		margin-left: -100px;
	}
	
	.new__slide2 {
		height: 450px;
		display: flex;
		align-items: flex-end;
		padding: 10px 0px;
		margin-right: -100px;
	}

	.carousel__landing__page .selected {
		transform: translateY(-10px) !important;
		transition: transform 1s ease-in-out;
	}
	.cs__slide__wrap{
		height: 490px !important;
	}
	

	.firstsecheading h1 {
		font-weight: 600;
		font-size: 33px;
	}
	.firstsecheading h2 {
		font-weight: 400;
		font-size: 33px;
	}

	.landingpagesecondsec {
		background-color: #f1f1f1;
		/* min-height: 700px; */
		width: 100%;
		/* padding-top: 24vh; */
	padding-top:calc(12vh + 100px);
	}

	.sec__screen_carousl{
		height:600px !important;
	}

}


.carousel__landing__page .selected {
	transform: translateY(-30px);
	transition: transform 1s ease-in-out;
}

.new__slide img {
	object-fit: contain;
	width: 100%;
	height: 100%;
	max-height: 500px;
}

.new__slide2 img {
	object-fit: contain;
	width: 100%;
	height: 100%;
	max-height: 500px;
}
.landingpagethirdsec {
	width: 100%;
	height: auto;
	position: relative;
	padding-bottom: 100px;
	background: url('../../assets/carousel/me_bg6.png') no-repeat right bottom 40px, linear-gradient(188.75deg, #0181c9 0%, #9208ff 100%);
	z-index: 1;
}
.third1 {
	margin-right: auto;
	transform: translateX(-15px) translateY(50px);
}
.third2 {
	margin-right: auto;
}
.third3 img {
	margin-left: auto;
}
.landingpagefooter {
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	/* gap: 20px; */
	background-color: #19191d;
	padding: 40px 20px;
}
.landingpagefooterheading {
	display: flex;
	flex-direction: column;
	gap: 20px;
	padding-bottom: 25px;
	border-bottom: 1px solid #3a3a3e;
}
.landingpagefooterheading h3 {
	max-width: 500px;
}
.landingpagelayout h4 {
	font-size: 24px;
	font-weight: 400;
}
.landingpagefooter span {
	font-weight: 500;
	font-size: 26px;
}
.landingpagedownloadbutton {
	text-decoration: none;
	color: white;
	width: 327px;
	height: 48px;
	margin: 0 auto;
	border-radius: 8px;
	min-width: 300px;
	background: linear-gradient(-90deg, #ff5917 0%, #9208ff 100%);
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}
.landingpagefooterend {
	padding: 20px 15px;
}
.landingpagefooterlogodiv {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 15px;
}
.landingpagelayout h6 {
	color: var(--cl_light_grey);
	font-weight: 400;
	/* font-size: 13px; */
}
.landingpagelayout h5 {
	font-size: 18px;
}
.landingfootermenu h6 {
	cursor: pointer;
}
.landingfootermenu h6:hover {
	text-decoration: underline;
}
.landingpagemenulinks {
	display: flex;
	flex-wrap: wrap;
	padding-top: 20px;
}
.landingfootermenu {
	min-width: 250px;
	display: flex;
	flex-direction: column;
	gap: 10px;
	padding-bottom: 15px;
}
.landingpagefootercopyright small {
	font-size: 13px;
	font-weight: 500px;
	color: white;
}
@media (min-width: 400px) {
	.first1,
	.first3 {
		display: block;
	}
	/* .landingpagesecondsec{

        padding-top: 170px;
    } */
}
