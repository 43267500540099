.sg{
    display: flex;
    align-items: center;
}
.sgcontainer{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
}
/* .sghead{
    padding
} */
.sg h1{
    font-size: 48px;
    font-weight: 600;
    padding-bottom: 20px;
}
.sgmsg{
    padding-bottom: 20px;
}
.sglegal p,.sgmsg{
    color: var(--cl_textgrey);
    font-size: .9rem;
    line-height: 1.3rem;
}
.sg .form{
    display: flex;
    flex-direction: column;
    /* gap: 15px; */
}
.sglabel{
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}
.sgcontainer input{
    height: 48px;
    border: none;
    border-radius: 8px;
    outline: none;
    padding-left: 15px;
    background-color: var(--cl_lightgrey);

}
.sglegal{
    display: grid;
    grid-template-columns: 20px 1fr;
    gap: 10px;
    padding: 5px 0;
}
.sglegal a{
    margin: 0 3px;
    color: var(--cl_orange);
    transition: 0.3s ease-in;
}

.sglegal a:hover{
    color: #fc5513;
    /* font-weight: 500; */
}
.sgbtn{
    height: 48px;
    border: none;
    outline: none;
    border-radius: 8px;
    color: var(--cl_textgrey);
}
.sg1 input, .sg2 input{
    width: 100%;
    margin-bottom: 10px;
}
.signupLabelDiv{
    padding-bottom: 10px;
}
.PhoneInput{
    border: .5px solid var(--cl_lightgrey);
    padding-left: 5px;
    border-radius: 8px;
}
.sg3{
    gap: 10px;
}
.sg3 input{
    width: 100% !important;
    height: 48px !important;
}
.react-tel-input .form-control{
    border: 1px solid var(--cl_lightgrey) !important;
    outline: none !important;
    background-color: var(--cl_bggrey) !important;
    width:100% !important;
    height: 48px !important;

}
.flag-dropdown{
    background-color: white !important;
    border: 1px solid var(--cl_lightgrey) !important;
}
.appearfromright{
    animation: slideleft 1s linear forwards ease-in-out;
}
@keyframes slideleft {
    0%   {
      transform: translateX(-100%);
    }
    /* 25%{
      border-image-source: linear-gradient(to right,#AC17FF, #FF5917);
    }
    50%{
      border-image-source: linear-gradient(to right, #FF5917,#AC17FF);
    }
    75%{
      border-image-source: linear-gradient(to right,#AC17FF, #FF5917);
    } */
    100% {
        transform: translateX(0%);
    }
  }

  @keyframes slide-1 {
    0%   {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-110%);
    }
  }

  @keyframes slide-1 {
    0%   {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-110%);
    }
  }


  .slide1{
    transform: translateX(0);
  }
  .slide2{
    transform: translateX(-100%);
  }
  .slide3{
    transform: translateX(-200%);
  }

  .slide-1{
    /* transform: translateX(-100%); */
    animation: slide-1 3s linear forwards ease-in;
  }
  .slide-2{
    transform: translateX(-200%);
  }
  /* .slide3{
    transform: translateX(-200%);
  } */
  .slidetransition{
      transition: 3s all ease-in-out;
  }