.selectbox{
    width: 100%;
    height: 40px;
    background-color: rgb(51, 53, 56) !important;
    border-radius: 5px;
    cursor: pointer;
    position: relative;
    /* overflow: hidden; */
}
.selectboxinput{
    color: white;
    padding-left: 10px;
    display: flex;
    align-items: center;
    /* overflow: hidden; */
}
.selectboxinputclr{
    color: grey;
}
.selectboxinput p{
    font-size: .9rem;
    font-family: 'inter',sans-serif;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.selectarrow{
    position: absolute;
    right: 10px;
    top: 16px;
    transition: transform 0.3s ease-in-out;
    transform: rotate(0);
}
.arrowdown{
    transform: rotate(-180deg);
}
.arrowleft{
    transform: rotate(-90deg);
}
.selectdropdown{
    background-color: white;
    color: black;
    transition: transform 0.3s ease-in-out,opacity 0.3s ease-in-out;
    position: absolute;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
    border-radius: 12px;
    width: 226px;
    right: 0;
}
.selectinactive{
    transform: translateY(0);
    visibility: hidden;
    opacity: 0;
    
}

.selectactive{
    transform: translateY(14px);
    visibility: visible;
    opacity: 1;
    z-index: 1000;
}
.selectdropdown span{
    height: 8px;
    width: 8px;
    background-color: white;
    position: absolute;
    transform: rotate(45deg);
    top: -4px;
    right: 15px;
    z-index: 750;

}
.selectdropdown .ul{
    z-index: 1000;
    /* height: 300px;
    max-height: 300px; */
    font-size: .9rem;
    padding: 5px 1px 5px 5px;
}
.selectdropdown .ul{
    scrollbar-width: 0px;
}

.selectdropdown li{
    height: 40px;
  list-style: none;
  /* margin: 3px; */
  /* padding: 6px 10px; */
  display: flex;
  align-items: center;
  padding-left: 10px;
  border-radius: 5px;
  transition: all 0.2s ease-in-out;
  margin-right: 5px;  
}
.selectdropdown li:hover{
  color: var(--cl_orange);
  background-color: var(--cl_light_orange);  
}