.personalinformaion-layout{
    
    min-height: 100vh;
    width: 100%;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    padding: 130px 40px 0 40px;
    margin-bottom: 50px;
    /* padding: 130px; */
}
 input:focus{
    outline: none;
 }

.personalinformaion-content{

    display: flex;
    /* border: 1px solid rgb(76, 0, 255); */
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    min-height: 100vh;
    /* padding: 130px 40px 0 40px; */
    /* width: 60vw;  */
    width: 688px;
    
}
@media only screen and (min-width: 1200px) {
    .personalinformaion-layout{
        padding: 130px 14% 0 14%;
    }
}
.personalinformaion-title{

    font-size: 24px;
    font-weight:600;
    padding-top: 28px;
    padding-bottom: 32px;
}


.personalinformaion-top-section {
    display: flex;
    /* border: 1px solid rgb(76, 0, 255); */
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 100%;   
    /* padding-left: 10px;  */

}


.personalinformaion-top-section .title{
    font-size:14px;
    font-weight: 550;
    padding-bottom:10px ;  
}

.personalinformaion-top-section .title{
    font-size:14px;
    font-weight: 600;
    padding-bottom:10px ;   
}
.personalinformaion-top-section .avatar{
    width: 80px;
    height: 80px;
    /* object-fit: contain; */
    overflow: hidden;
    border-radius:12px;
    /* padding: 10px */
}

.personalinformaion-top-section .avatar img{
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
}

.personalinformaion-top-section .endnote {
    /* height: 100%; */
    /* width: 50%; */
    font-size: 0.7rem;
    /* font-weight: lighter; */
    color: rgb(179, 179, 179);
    width: 17vw;
    padding-top: 10px;

}


.personalinformaion-top-section .avatar-buttons{
    display: flex;
    /* border: 1px solid rgb(76, 0, 255); */

    /* justify-content: space-around; */
    width: 50%;
    padding: 10px 0px 0px 0px
}



.personalinformaion-middle-section{
    display: flex;
    /* border: 1px solid rgb(76, 0, 255); */
    flex-direction: column;
    justify-content: center;
    align-items:flex-start;
    text-align: left;
    width: 100%;
    padding-top: 20px
    
}
.personalinformaion-middle-section .input-box{

    display: flex;
    /* border: 1px solid rgb(76, 0, 255); */
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    padding: 10px 0 10px 0;
    width: 100%;
 
}

.personalinformaion-middle-section .input-box input,.personalinformaion-middle-section .input-box textarea{

width: 100%;
height: 40px;
border: none;
background: var(--cl_light_grey);
border-radius:8px;
overflow: hidden;
padding-left:15px;
/* border: 1px solid rgb(76, 0, 255); */

}
.personalinformaion-middle-section .input-box textarea{
    font-size: .9rem;
    padding-top: 15px;
    font-family: 'inter',sans-serif;
}
.personalinformaion-middle-section .input-box p {
   font-size:14px;
   font-weight: 550;
   padding-bottom:10px ;
    /* border: 1px solid rgb(76, 0, 255); */   
}
/* .personalinformaion{
    
} */


.personalinformaion-end-section{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
    padding: 15px 0;
    
}
.invBtn{
    background-color: white;
    color: black;
    /* padding: 8px 25px 8px 25px; */
    border-radius: 8px;
    font-size: 0.8rem;
    white-space: nowrap;
    border: 1px solid #E2E2F0;
    cursor: pointer;
    font-weight: 500;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 25px;
}
.invBtn:hover{
background-color: var(--cl_lightgrey);
}
/* .invBtn2:hover{
transform: scale(1.1);
} */
.invBtn2{
    margin-left: 10px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    width: 56px;
    border: 1px solid rgba(226, 226, 240, 1);
    border-radius: 8px;
    cursor: pointer;
}
.invBtn2:hover{
    background-color: var(--cl_lightgrey);
}
.btn{
    background-color: var(--cl_orange);
    color: white;
    /* padding: 9px 18px 9px 18px; */
    border-radius: 5px;
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: 500;
    white-space: nowrap;
    height: 40px;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease-in-out;
    min-width: 115px;
}
.btn:hover{
    background-color: var(--cl_light_orange);
    color: var(--cl_orange);
}

.ProfilePageDpImg {
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center;
    -o-object-fit: cover;
    border-radius: 15px;
  }