/* @import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@300;400;500;700&display=swap'); */
.topBar{
    height: 106px;
    width: 100%;
    position: fixed;
    z-index: 1000;
    background-color: white;
    
    /* overflow: hidden;
    overflow: -moz-hidden-unscrollable; */
    box-shadow: 0px 1px 1px rgba(226, 226, 240, 0.5);
}
.topBarContainer{
    width: 100%;
    height: 100%;
    padding: 0 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.topBarLeft{
    /* display: flex; */
    display: grid;
    /* grid-template-columns: 91.15px minmax(40px,1fr); */
    grid-template-columns: 82px auto;
    align-items: center;
    justify-content: space-between;
    gap: 32px;
    /* max-width: 368px; */
}
.logoMain{
    position: relative;
    height: 48px;
    width: 82px;
    min-width: 82px;
    max-width: 82px;
    min-height: 48px;
    max-height: 48px;
    cursor: pointer;
    object-fit: contain;
    /* -o-object-fit: cover;
    object-position: center;
    -o-object-position: center; */
}
.logoMain svg:nth-child(1){
    position: absolute;
    top: -10px;
    right: -20px;

}
.logoMain img{
    /* width: 100%; */
    /* height: 100%; */
    height: 48px;
    width: 82px;
    min-width: 82px;
    max-width: 82px;
    min-height: 48px;
    max-height: 48px;
}
/* .mainsearchwrapper{
    display: flex;
    position
} */
.mainsearchdropdown{
    width: 560px;
    height: auto;
    /* max-height: 464px; */
    /* max-height: calc(95vh - 106px); */
    background-color: white;
    position: absolute;
    top: 50px;
    left: 0;
    /* box-shadow: 0px 0px 1.5px rgb(202, 201, 201); */
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    display: flex;
    flex-direction: column;
    align-content: center;
    padding: 5px 0;
    transition: all 0.3s ease-in-out;
    /* overflow-y: scroll;
    overflow-x: hidden; */
}
.mainsearchdropdown.inactive{
    opacity: 0;
    visibility: hidden;
    transform: translateY(-20px);
}
.mainsearchdropdown.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}
.mainsearchdropdown .searchheadings{
    color: #7D7D8D;
    font-size: 13px;
    padding: 10px 26px;
}
.eventitem{
    width: 544px;
    min-height: 72px;
    padding: 12px 16px;
    align-self: center;
    display: flex;
    flex-direction: row;
    gap: 12px;
    transition: background 0.3s ease-in-out;

}
.eventitem:hover{
    background: rgba(255, 89, 23, 0.08);
}
.eventitem img, .eventitemimgloader{
    height: 48px;
    width: 48px;
    border-radius: 8px;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
}
.eventitemuserdetails{
    display: flex;
    gap: 3px;
}
.eventitemuserdetails span{
    font-size: var(--font-size-10);
    color: #7D7D8D;
    align-self: center;
}
.eventitemuserdetails .dot{
    
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: rgb(143, 142, 142);

}
.eventitemcontent{
    display: flex;
    flex-direction: column;
    gap: 6px;
}
.eventitemcontent h6{
    font-size: 13px;
    font-weight: 600;
}
.eventitemuser{
    display: flex;
    flex-direction: row;
    gap: 8px;

}
.eventitemuser img, .eventitemuserimgloader{
    height: 16px;
    width: 16px;
    border-radius: 50%;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
}
.peopleitem{
    width: 544px;
    min-height: 48px;
    padding: 12px 16px;
    align-self: center;
    display: flex;
    flex-direction: row;
    gap: 8px;
    transition: background 0.3s ease-in-out;
}
.peopleitem:hover{
    background: rgba(255, 89, 23, 0.08);
}
.peopleitem img, .peopleitemimgloader{
    height: 24px;
    width: 24px;
    border-radius: 50%;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center; 
    margin-right: 4px;
}
.peopleitem h6{
    font-size: 13px;
    font-weight: 600;
    align-self: center;
}
.peopleitem span{
    font-size: var(--font-size-13);
    color: #7D7D8D;
    align-self: center;
}

.mainSearch{
    /* width: 100%; */
    /* max-width: 254px; */
    display: grid;
    grid-template-columns: 1fr 20px;
    position: relative;
    border-radius: 8px;
    background-color: var(--cl_bg-grey);
    padding: 0 10px;
}
.search{
    height: 40px;
    /* padding-left: 15px; */
    border: none;
    outline: none;
    border-radius: 8px;
    background-color: var(--cl_bg-grey);
    font-family: "Inter", sans-serif;
}
.searchnoresults{
    font-size: .7rem;
    padding: 10px 26px;
    color: #7D7D8D;
}


.topBarRight{
    display: flex;
    gap: 20px;
}
.mainTabs{
    display: flex;
    border-right: 1px solid var(--cl_light_grey);
    padding-right: 10px;
}
.mainTabscontainer{
    position: relative;
}
.mainTabscontainer .menubtn{
    display: none;
}
.menubtn{
    height: 40px;
    width: 40px;
    min-height: 40px;
    display: flex;
    align-content: center;
    justify-content: center;
    border-radius: 50%;
    border: .5px solid #E2E2F0;
}
.menubtn svg{
    margin: 12px 12px;
}
.mainTabs span{
    display: none;
    position: absolute;
    top: -5px;
    right: 20px;
    height: 10px;
    width: 10px;
    background-color: white;
    border-left: 2px solid rgba(0, 0, 0, 0.1);
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    transform: rotate(45deg);
}
.mainTabscontainer .mainTabs.inactive{
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}
.mainTabscontainer .mainTabs.active{
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}
@media (max-width: 950px){
    .mainTabscontainer .menubtn{
        display: block;
    }
    .mainTabscontainer .mainTabs{
        position: absolute;
        display: flex;
        flex-direction: column;
        background: #ffffff;
        border-radius: 10px;
        top: 55px;
        /* left: -10px; */
        right: -4px;
        min-width: 220px;
        box-shadow: 0 1px 8px rgb(0 0 0 / 30%);
        transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s;
        padding: 8px;
    }
    .mainTabscontainer .mainTabs.inactive{
        opacity: 0;
        visibility: hidden;
        transform: translateY(-20px);
    }
    .mainTabscontainer .mainTabs.active{
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
    .mainTabs span{
        display: block;
    }
}
.navLink{
    height: 40px;
    /* min-width: 80px; */
    display: flex;
    align-items: center;
    padding: 0 15px;
    border-radius: 8px;
    font-weight: 500;
    font-size: var(--font-size-13);
}
.navLinkActive{
    color: var(--cl_orange);
    background-color: var(--cl_light_orange);
}

.dropdowns{
    display: flex;
    gap: 8px;
}
.bellcontainer{
    height: 40px;
    width: 40px;
}
.bell{
    height: 40px;
    width: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--cl_light_orange);
    cursor: pointer;
}
/* .bell:hover{
    background-color: rgba(255, 89, 23, 0.8);
}
.bell:hover > svg path{
    stroke: white;
} */
/* .bell svg{
    margin-top: 4px;
} */
.settingicon{
    border-radius: 50%;
}
.settingsicon svg{
    fill: none;
    cursor: pointer;
}
.settingsicon svg:hover > circle{
    fill: var(--cl_bg-grey);
}
.settingsicon svg circle{
    fill: white;
    stroke: #E2E2F0;
}
.settingsicon svg path{
    stroke: #040405;
}
.topBarRight .mainBtn{
    min-width: 95px;
    display: block;
}
.navBtn a{
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 11px;
    font-size: var(--font-size-13);
}
@media (min-width: 1400px){
    .topBarContainer{
        padding: 0 14%;
    }
}

@media (min-width: 1400px){
    .topBarContainer{
        padding: 0 14%;
    }
}
.mainSearch svg{
margin: 13px 3px;
}
.mainSearch svg path{
    stroke: #7D7D8D;
}

