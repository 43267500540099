.footer{
  /* height: 560px; */
  /* min-height: 560px; */
  width: 100%;
  background-color: #16161a;
  z-index: 10;
}
.footerContainer{
  height: 100%;
  width: 100%;
  background-repeat: no-repeat;
  background-position: left;
  /* background-blend-mode: hard-light;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%); */
}
.footerContent{
  height: 100%;
  width: 100%;
  /* padding: 80px 40px; */
  color: white;
}
.subscribe{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}
.subscribe h1{
  font-size: 2rem;
  color: white;
}
.subscribeInput{
  display: flex;
  gap: 10px;
}
.subscribeMail{
  /* width: 418px; */
  height: 40px;
  border: none;
  outline: none;
  border-radius: 8px;
  padding-left: 15px;
  color: white;
  background-color: rgba(53, 53, 58, 1);
}
.footerContent hr{
  margin-top: 70px;
  border: none;
  height: 1px;
  background-color: rgb(53, 53, 53);
}
/* .footerMain{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.footerMain1{
  width: 100%;
  padding: 50px 0 0 0;
  display: grid;
  grid-template-columns: 336px 1fr;
}


.footerMain2{
  display: flex;
  padding-top: 20px;
  gap: 5px;
} */
.footerMain2 p{
  font-size: var(--font-size-13);
}
/* .footerSection1{
  display: flex;
  flex-direction: column;
  gap: 15px;
} */
.footerSection1 img{
  height: 48px;
  width: 96px;
}
.footerMain2 span, .footerSection2 span{
  color: rgba(125, 125, 141, 1);
  font-size: var(--font-size-13);
}
.footerSection1 span {
  color: rgba(125, 125, 141, 1);
  font-size: var(--font-size-13);
  line-height: 21px;
}
/* .footerSection2{
  display: flex;
  justify-content: space-between;
  padding: 0 0 0 60px;
  max-width: 600px;
} */


.footerSection2 h3{
  color: var(--cl_orange);
  /* padding-bottom: 25px; */
}
.footerSection2 span{
  cursor: pointer;
}
.footerSub{
  display: flex;
  flex-direction: column;
  /* gap: 5px; */
}
/* @media (min-width: 1200px) {
  .footerContent{
    padding: 80px 14%;
  }
}
@media (min-width: 1600px) {
  .footerMain2{
    margin-top: 30px;
  }
} */