.loginPageLayout {
    height: 100vh;
    width: 100vw;
    font-family: inherit;
    display: grid;
    grid-template-columns: 1fr 1fr;
}
.loginPageInputSection{
    height: 100%;
    width: 100%;
    /* background-color: red; */
}
.loginPageInputDiv {
    /* height: 90%; */
    width: 100%;
    height: 100%;
    margin: auto auto;
    display: grid;
    grid-template-rows: 48px 1fr;
    /* display: flex;
    flex-direction: column; */
    /* gap: 25px; */
    /* max-width: 500px; */
    /* padding-left: 50px; */
  }
  @media only screen and (min-width: 600px) {
    .loginPageInputDiv{
      width: 512px;
    }
  }
.loginPageLogo img{
    height: 48px;
}

.main__categories__wrapper{

  /* display:flex;
  width: 100%;
  height: 100%;
  padding-top: 30px; */

}