
/* TAGS */

.tagsinputcontain {
    transition: 0.3s ease-in all;
  }
  .organiser,
  .addtags {
    display: flex;
    flex-direction: column;
    /* gap: 15px; */
    text-align: left;
    gap: 15px;
    /* margin-top: 20px; */
    /* padding: 10px 0; */
  }
  /* .organiser span,
  .addtags span {
    border: 1px solid black;
  } */
  .tags-input {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    /* min-height: 48px; */
    min-height: 40px;
    width: 511px;
    padding: 0 4px;
    /* margin-top: 10px; */
    /* border: 1px solid #d6d8da; */
    background-color: rgba(245, 245, 246, 1);
    border-radius: 6px;
  }
  .tags-input ul {
    padding-left: 0 !important;
  }
  
  /* .tags-input:focus-within {
    border: 1px solid #ff5917;
  } */
  .tags-input ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
    /* height: 35; */
    margin-bottom: 0 !important;
  }
  .tags-input input {
    flex: 1;
    border: none;
    height: 40px;
    font-size: .8rem;
    /* padding: 4px 0 0 0; */
    padding: 0 0 0 10px;
    background-color: rgba(245, 245, 246, 1);
    font-family: "Inter", sans-serif;
  }
  .tags-input input:focus {
    outline: transparent;
  }
  
  #tags {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    /* margin: 8px 0 0 0; */
  }
  
  .tag {
    width: auto;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* color: #fff; */
    padding: 0 8px;
    font-size: 14px;
    list-style: none;
    border-radius: 6px;
    margin: 1px 2px;
    border: 1px solid lightgray;
    background-color: white;
    border-radius: 10px;
    /* background: orangered; */
    max-width: 225px;
    /* min-width: 235px; */
  }
  .tag .tag-title {
    margin-top: 3px;
  }
  .tag .fa-times-circle {
    display: block;
    width: 16px;
    height: 16px;
    line-height: 16px;
    text-align: center;
    font-size: 14px;
    margin-left: 8px;
    color: #ff5917;
    border-radius: 50%;
    background: #fff;
    cursor: pointer;
  }
  .searchtagscontainer {
    height: auto;
    min-height: 35px;
    position: relative;
    /* width: 480px; */
    width: 511px;
    overflow: hidden;
    margin-top: 10px;
    border-radius: 15px;
    border: 1px solid rgb(219, 219, 219);
    max-height: 200px;
  }
  /* .tri {
    content: "";
    display: block;
    position: absolute;
    top: -10px;
    width: 10px;
    height: 10px;
    background-color: red;
    z-index: 100;
  } */
  .searchtags {
    width: 505px;
  
    /* height: 141px; */
    max-height: 200px;
  
    overflow: auto;
  }
  .searchtags::-webkit-scrollbar {
    width: 5px;
  }
  /* Track */
  .searchtags::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  .searchtags::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  /* Handle on hover */
  .searchtags::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .tagitem {
    height: 35px;
    display: flex;
    flex-direction: row;
    cursor: pointer;
    /* border: 1px solid blue; */
  }
  .tagitem:hover{
    background-color: #c4e2f5;
  }
  .tagcheckboxcontainer {
    display: block;
    /* align-items: center;
    justify-content: center; */
    position: relative;
    width: 15px;
    height: 15px;
    margin: 8px 15px 10px 10px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-size: 14px;
    color: white;
    /* border: 1px solid black; */
  }
  .tagcheckboxcontainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .tagcheckmark {
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    background-color: rgb(240, 236, 236);
    border: 0.5px rgb(80, 80, 80);
    border-radius: 3px;
  }
  
  .tagcheckboxcontainer:hover input ~ .tagcheckmark {
    background-color: #ccc;
  }
  .tagcheckboxcontainer input:checked ~ .tagcheckmark {
    background-color: var(--cl_orange);
  }
  .tagcheckmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  .tagcheckboxcontainer input:checked ~ .tagcheckmark:after {
    display: block;
  }
  .tagcheckboxcontainer .tagcheckmark:after {
    left: 5px;
    top: 1px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 1px 1px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .tagdetailsdiv {
    height: 100%;
    width: 90%;
    display: flex;
    align-items: center;
    /* border: 1px solid green; */
    border-bottom: 0.2px solid #efeff0;
    gap: 10px;
  }
  .tagdetailsdiv img {
    width: 25px;
    height: 25px;
    background-color: #8693a0;
    border-radius: 50%;
    outline: none;
    border: none;
    object-fit: cover;
    object-position: center;
  }
  .tagpeoplename {
    width: 27%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 500;
    font-size: 0.7rem;
    /* margin: 0 0 0 10px; */
  }
  .tagpeopleusername {
    font-size: 0.7rem;
    width: 20%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: rgb(145, 144, 144);
  }
  .noresult {
    font-size: 0.8rem;
    color: rgb(145, 144, 144);
    margin-left: 10px;
  }
  .tag .tagdetailsdiv {
    width: 100%;
  }
  .tag .tagpeoplename {
    width: 40%;
  }
  @media screen and (max-width: 567px) {
    .tags-input {
      width: calc(100vw - 32px);
    }
  }