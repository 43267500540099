.content_moderator {
    width: 100%;
    font-family: 'Inter', sans-serif;
    min-height: 100vh;
    /* max-height: 100vh; */
    display: grid;
    grid-template-rows: 104px 1fr;

}

.content_moderator_wrapper {

    /* padding: 152px 14% 50px 14%; */
    padding: 104px 0 0 0;
}

.content_moderator_content {
    width: 100%;
    height: 100%;
    padding: 48px 40px 0 40px;
    min-height: 100vh;
}

.content_moderator_container {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 122px 1fr;
    gap: 24px;
}

@media (min-width: 1000px) {
    .content_moderator_container {
        width: 100%;
        height: 100%;
        display: grid;
        grid-template-columns: 322px 1fr;
        gap: 24px;
    }
}

.content_moderator_routes_wrapper {
    position: relative;
}

.content_moderator_routes {
    display: flex;
    flex-direction: column;
    gap: 24px;
    position: sticky;
    top: 152px;
}

.c_m_profile {
    display: flex;
    flex-direction: column;
    gap: 20px;

}

.c_m_profileimg,
.c_m_profileimg img {
    width: 72px;
    height: 72px;
    border-radius: 16px;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
}

.c_m_profileinfo {
    line-height: 24px;
}

.c_m_profileinfo h4 {
    font-size: 16px;
}

.c_m_profileinfo span {
    font-size: 12px;
    color: #7D7D8D;
}

.c_m_routes {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 4px;
}

.c_m_routeitem {
    width: 100%;
    height: 40px;
    display: grid;
    grid-template-columns: auto 1fr;
    padding: 0 1rem;
    gap: 1rem;
    border-radius: 8px;
    align-items: center;
    cursor: pointer;
    text-decoration: none;
    color: black;

}

.cm_routeitem_readonly {
    color: var(--cl_textgrey) !important;
}

.cm_routeitem_readonly svg {
    stroke: var(--cl_textgrey) !important;
}

.c_m_routeitem h4 {
    display: none;
}

@media (min-width: 1000px) {
    .c_m_routeitem {
        width: 100%;
        height: 40px;
        display: grid;
        grid-template-columns: auto 1fr;
        padding: 0 1rem;
        gap: 1rem;
        border-radius: 8px;
        align-items: center;
        cursor: pointer;
        text-decoration: none;
        color: black;
    }

    .c_m_routeitem h4 {
        display: block;
    }
}

.c_m_routeitem_active {
    background-color: var(--cl_light_orange);
}

.c_m_routeitem:hover {
    background-color: var(--cl_bggrey);
}

.c_m_routeitem_active:hover {
    background-color: var(--cl_light_orange);
}

.c_m_routeitem svg {
    stroke: black;
}

.c_m_routeitem_active svg {
    stroke: var(--cl_orange);
}

.c_m_routeitem_active h4 {
    color: var(--cl_orange);
}

.content_moderator_contents_container {
    position: relative;
}

.content_moderator_contents_wrapper {
    position: sticky;
    position: -webkit-sticky;
    top: 152px;
    width: 100%;
    /* min-height: calc(100vh - 152px); */
    height: calc(100vh - 152px) !important;
}

.content_moderator_contents>div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 2.5rem;
}

.contentmoderator_minicontent {
    height: auto;
    background: #FFFFFF;
    border: 1px solid #E2E2F0;
    box-sizing: border-box;
    box-shadow: inset 0px -1px 0px #E2E2F0;
    border-radius: 12px;
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.cm_minicontent_header {
    display: flex;
    justify-content: space-between;
}

.cm_minicontent_header a {
    color: var(--cl_orange);
    text-decoration: none;
    font-size: 14px;
}

.cm_minicontent_header h3 {
    font-size: 1rem;
}

.cm_content_item {
    min-height: 56px;
    display: flex;
    gap: 1rem;
    padding: 1rem 0;
}

.cm_content_item_border {
    border-top: .5px dashed #E2E2F0;
    border-bottom: .5px dashed #E2E2F0;
}

.cm_content_item img {
    height: 64px;
    width: 64px;
    object-fit: cover;
    object-position: center;
    border-radius: 8px;
}

.cm_contentitem_info {
    line-height: 20px;
}

.cm_contentitem_info h4 {
    font-size: 14px;
}

.cm_contentitem_info p {
    font-size: 13px;
    color: #7D7D8D;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.cm_contentitem_user {
    display: flex;
    gap: .5rem;
    margin-top: .5rem;

}

.cm_contentitem_user img {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    align-self: center;
}

.cm_contentitem_user span {
    font-size: 13px;
}

.cm_content_statbtn,
.cm_content_statbtn_big {
    min-width: 96px;


    border-radius: 6px;
    outline: none;
    border: none;
    font-size: 10px;
    display: flex;
    align-items: center;
    gap: .5rem;
    text-transform: uppercase;

    padding: 0 12px;
    font-weight: 600;
    margin-top: 4px;

}

.cm_content_statbtn {
    height: 24px;
}

.cm_content_statbtn_big {
    height: 40px;
    width: 128px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.cm_content_statbtn_green {
    background: rgba(50, 160, 113, 0.08);
    color: #32A071;
}

.cm_content_statbtn_red {
    background: rgba(255, 58, 68, 0.08);
    color: #FF3A44;
}

.cm_path_two {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

@media (min-width: 1300px) {
    .cm_path_two {
        display: grid;
        grid-template-columns: 1fr 322px;
        gap: 24px;
    }
}

.cm_path_one {
    display: grid;
    grid-template-columns: 1fr;
}

.cm_postitem {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #E2E2F0;
    box-sizing: border-box;
    box-shadow: inset 0px -1px 0px #E2E2F0;
    border-radius: 12px;
    /* width: 668px; */
    height: auto;
    /* max-height: 584px; */
    overflow: hidden;
}

.cm_postitem_info {
    padding: 24px;
    display: flex;
    gap: 24px;
}

.cm_postitem_user,
.cm_postitem_event {
    display: flex;
    gap: 12px;
}

.cm_postitem_user img,
.cm_postitem_event img {
    width: 32px;
    height: 32px;
    object-fit: cover;
    object-position: center;
}

.cm_postitem_user img {
    border-radius: 50%;
}

.cm_postitem_event img {
    border-radius: 8px;
}

.cm_postitem_detail {
    line-height: 16px;
}

.cm_postitem_detail h4 {
    font-size: 13px;
    font-weight: 600;
}

.cm_postitem_detail small {
    font-size: 11px;
    color: #7D7D8D;
}

.cm_postitem_arrowright {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    border: 1px dashed #E2E2F0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cm_postitem_container {
    padding: 0 4px 4px 4px;
}

.cm_postitem_imgcontainer {
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    height: 100%;
    max-height: 50vh;
}

.cm_postitem_imgcontainer img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.cm_controls {
    display: flex;
    justify-content: space-between;
    height: 40px;
}

.cm_controls_1 {
    display: flex;
    gap: .5rem;
}

.cm_controls_btn {
    width: 40px;
    height: 40px;
    background: #FFFFFF;
    border: 1px solid #E2E2F0;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cm_controls_btn_inv svg {
    transform: rotate(-180deg);
}

.cm_controls_btn_report svg {
    stroke: black;
}

.cm_controls_buttons {
    display: flex;
    align-items: center;
    gap: .5rem;
}

.content_moderator_content-moderator {
    display: flex;
    flex-direction: column;
    /* display: grid;
    grid-template-rows: auto auto auto; */
    gap: 24px;
    padding-bottom: 50px;
}

.cm_eventitem_details {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.cm_eventitem_details p {
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
    color: #7D7D8D;
}

.cm_eventitem_eventinf {
    display: flex;
    justify-content: space-between;
}

.cm_eventitem_heading {
    display: flex;
    justify-content: space-between;
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
}

.cm_eventitem_heading h3 {
    font-weight: 600;
    font-size: 18px;
    line-height: 26px;
}

.cm_eventitem_more {
    display: flex;
    gap: 1rem;
    max-height: 2ch;
    /* display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr; */
}

.cm_eventitem_moreitem {
    display: flex;
    gap: .5rem;
    align-items: center;
}

.cm_eventitem_moreitem span,
.cm_eventlocation {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #040405;
    /* width: 100%; */
    line-clamp: 1;
    -webkit-line-clamp: 1;
    /* white-space: nowrap; */
    text-overflow: ellipsis;
    overflow: hidden;
    height: 2ch;
}

.cm_detail_table {
    border: 1px solid #E2E2F0;
    box-sizing: border-box;
    box-shadow: inset 0px -1px 0px #E2E2F0;
    border-radius: 12px;
    display: grid;
    grid-template-rows: auto 1fr;
    min-height: 558px;
    height: 80vh;
    min-height: 600px;

}

.cm_detail_table {
    padding: .5rem;
    position: relative;
}

.cm_detail_table li {
    list-style: none;
}

.cm_table_head,
.cm_table_item {
    width: 100%;


    display: grid;
    grid-template-columns: 3fr 2fr 2fr 1fr 1fr 2fr 1fr;
    gap: 16px;
    height: 40px;
}

.cm_table_head {
    background: #F5F5F6;
    border-radius: 8px;
    padding: 0 1rem;
}

.cm_table_item {
    min-height: 72px;
}

.cm_table_head li {
    height: 40px;
    display: flex;
    align-items: center;
    /* justify-content: center; */
}

.cm_table_item li {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.cm_table_container {
    padding: 0 1rem;
}

.cm_table_container_border {
    border-top: 1px dashed #E2E2F0;
}

.cm_table_img {
    height: 40px;
    width: 40px;
    border-radius: 8px;
    object-fit: cover;
    object-position: center;
}

.cm_table_usrimg {
    height: 24px;
    width: 24px;
    min-height: 24px;
    min-width: 24px;
    border-radius: 50%;
    object-fit: cover;
    object-position: center;
    border: .5px solid white;
}

.cm_table_usrimg0 {
    z-index: 5;
}

.cm_table_usrimg1 {
    transform: translateX(-20px);
    z-index: 4;
}

.cm_table_usrimg2 {
    transform: translateX(-40px);
    z-index: 3;
}

.cm_table_usrimgcount {
    height: 24px;
    width: 24px;
    background-color: var(--cl_light_orange);
    color: var(--cl_orange);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    transform: translateX(-60px);
    z-index: 2;
}

.cm_path_one {
    height: 100%;
    /* min-height: calc(100%-200px); */
    display: grid;
    grid-template-rows: 1fr;
    gap: 1rem;
}

/* .cm_table_head{
    position: sticky;
    top: 0.5rem;
    right: 0.5rem;
} */

.cm_home {
    width: 100%;
    height: 100%;
    padding-top: 48px;
    display: flex;
    flex-direction: column;
    gap: 40px;
}

.cm_home_head {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.cm_home_header {
    display: flex;
    flex-direction: column;
    gap: 8px;
    max-width: 443px;
}

.cm_home_header span {
    font-size: 13px;
    color: var(--cl_textgrey);
}

/* .cm_home_content{

} */
.cm_home_contentcontainer {
    display: grid;
    grid-template-columns: 1fr 332px;
    gap: 16px;
    height: 100%;
}

.chat_topbar .topBarRight {
    margin-left: auto;
}

.cm_contentinfo_tiles_container_wrap {
    border-bottom: 1px dashed #E2E2F0;
    /* padding-bottom: 13px; */
    position: relative;
}

.cm_contentinfo_tiles_container {
    display: flex;
    gap: 8px;

    position: relative;
    flex-wrap: wrap;
    padding-bottom: 24px;
    max-height: 225px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;

}

.cm_contentinfo_tiles_container_open {
    display: flex;
    gap: 8px;
    /* border-bottom: 1px dashed #E2E2F0; */
    position: relative;
    flex-wrap: wrap;
    padding-bottom: 24px;
    max-height: 119px;
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.cm_contentinfo_tiles_container_open .cm_infotile {
    transition: all 0.3s ease-in-out;
    margin-bottom: 16px;
}

.cm_info_open,
.cm_info_close {
    position: absolute;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* left: calc(50%-12px); */
    right: 50%;
    left: 50%;
    bottom: -12px;

    background: #F5F5F6;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
}

.cm_info_open svg {
    transition: transform 0.3s ease-in-out;
    transform: rotate(180deg);
}

.cm_info_close svg {
    transition: transform 0.3s ease-in-out;
    transform: rotate(0deg);
}

.cm_infotile {
    background: #FFFFFF;
    border: 1px solid #E2E2F0;
    box-sizing: border-box;
    box-shadow: inset 0px -1px 0px #E2E2F0;
    border-radius: 12px;
    width: 160px;
    height: 96px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 17px;
}

.cm_infotile h1 {
    font-size: 18px;
    line-height: 16px;
    display: flex;
    gap: 10px;
}

.cm_infotile span {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #7D7D8D;
    word-break: break-word;
}

/* analytics */
.cm_analytics_wrapper {
    width: 100%;
    height: 100%;
    display: grid;
    /* grid-template-rows:1fr 1fr; */
    gap: 1rem;
    padding-bottom: 100px;

}

.cm_analyticschart_container {
    max-width: 100%;
    min-height: 536px;
    border: 1px solid #E2E2F0;
    box-sizing: border-box;
    box-shadow: inset 0px -1px 0px #E2E2F0;
    border-radius: 12px;

}

.cm_linechart {
    padding: 24px;
    /* border: 1px solid #E2E2F0; */
    box-sizing: border-box;
    /* box-shadow: inset 0px -1px 0px #E2E2F0; */
    /* border-radius: 12px; */
    max-height: 200px;
    max-width: 100%;
    padding: .5rem .7rem;
}

.recharts-responsive-container {
    min-height: 184px;
    width: 100%;
    transform: translateX(-10px);
}


/* super admin */
.cm_list_container {
    padding: 2rem 1.5rem;
    position: relative;
    border: 1px solid #E2E2F0;
    box-sizing: border-box;
    box-shadow: inset 0px -1px 0px #e2e2f0;
    border-radius: 12px;
    display: grid;
    grid-template-rows: 32px 1fr;
    min-height: 600px;
    height: 80vh;
    gap: 2rem;
}

.cm_list_roleselect {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    min-width: 250px;
    max-width: 350px;
}

.cm_list_roleselect .selectbox {
    background-color: var(--cl_bggrey) !important;
}

.cm_list_roleselect .selectboxinput {
    color: black !important;
}





.moderatorlistitem {
    /* width: 544px; */
    width: 100%;
    min-height: 48px;
    padding: 12px 16px;
    align-self: center;
    display: flex;
    flex-direction: row;
    gap: 8px;
    transition: background 0.3s ease-in-out;
    background-color: var(--cl_bg-grey);
    border-radius: 8px;
}

.moderatorlistitem:hover {
    background: rgba(255, 89, 23, 0.08);
}

.moderatorlistitem img {
    height: 32px;
    width: 32px;
    border-radius: 50%;
    object-fit: cover;
    -o-object-fit: cover;
    object-position: center;
    -o-object-position: center;
    margin-right: 4px;
}

.moderatorlistitem h6 {
    font-size: 13px;
    font-weight: 600;
    align-self: center;
}

.moderatorlistitem span {
    font-size: var(--font-size-13);
    color: #7D7D8D;
    align-self: center;
}

.moderatorcsvdownload {
    margin-left: auto;
    height: 32px;
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.moderatorcsvdownload:hover svg {
    color: var(--cl_orange);
    fill: var(--cl_orange);
}

.moderatorlistitem svg {
    /* margin-left: auto; */
    font-size: 1.5rem;
    transition: all 0.3s ease-in-out;

}

/* .moderatorlistitem svg:hover{

} */
.moderatorlistscroll>div {
    display: flex;
    flex-direction: column;
    gap: 5px;
}