.reportPostWrapper{
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.reportPostWrapper h2{
    display: flex;
    align-items: center;
    justify-content: center;
}
.reportPostWrapper span{
    transition: all 0.3 ease-in-out;
}
.closeBtn{
    position: absolute;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    top: 10px;
    right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3 ease-in-out;
}
.closeBtn:hover{
    background-color: var(--cl_orange);
}
.closeBtn:hover svg{
    color: white;
}

.reportlist{
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-top: 20px;
  }
  .reportlist li {
    list-style: none;
    height: 40px;
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding-left: 12px;
    font-size: 1rem;
  }
  .reportlist li:hover{
    background-color: var(--cl_light_orange);
    color: var(--cl_orange);
  }
  .ReportBtnContainer{
      display: flex;
      justify-content: flex-end;
  }
  .ReportBtnContainer .mainBtn{
      min-width: 100px;
  }