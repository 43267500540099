.mailVerified{
    display: flex;
    flex-direction: column;
    gap: 32px;
}
.mailVerified h1{
    font-weight: 500;
    font-size: 48px;
}
.mailVerified b{
    font-weight: 700;

}
.mailVerified small{
    font-size: 13px;
    color: #7D7D8D;

}
.mailVerified span{
    line-height: 25px;
}